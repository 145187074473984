import "./InstitutionList.scss";
import React, { useEffect, useRef, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useDebounce } from "primereact/hooks";
import { useNavigate } from "react-router";
import {
  setIsFhsAvailable,
  setIsMarketing,
  setIsPro,
} from "../../../Redux/subscriptionSlice";
import {
  resetFIType,
  setHealthAnalysisID,
  setOrgId,
  setOrgKey,
  setOrgName,
  setOrgShortName,
  setSelectedFI,
} from "../../../Redux/orgDetailsSlice";
import { setPerformaLoading } from "../../../Redux/loaderSlice";
import {
  getHealthAnalysisStatusList,
  SUBSCRIPTION,
} from "../../../utils/global";
import {
  fetchFinancialHealthScoreExpanded,
  fetchFinancialHealthScoreSummary,
  resetFHSExpanded,
  resetFHSSummary,
  setIsFHSReset,
  setShowGaugeChart,
  setSkipAPICall,
} from "../../../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import {
  fetchHealthAnalysisSlice,
  resetHealthAnalysis,
  setIsSFTPFileUploading,
} from "../../../rtk-query/features/healthAnalysis/healthAnalysisSlice";
import { allCohortThunk } from "../../../Redux/allCohortSlice";

const InstitutionList = ({ profileBoxRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autoCompleteRef = useRef();
  const authReducer = useSelector((state) => state.authReducer);
  const orgReducer = useSelector((state) => state.orgReducer);
  const [institutions, setinstitutions] = useState([]);
  const [searchTyping, setSearchTyping] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState({});
  const [onSelectedInstitution, setOnSelectedInstitution] = useState(false);
  const [filteredinstitutions, setFilteredinstitutions] = useState([]);
  const [, debouncedSearchValue, setSearchValue] = useDebounce("", 250);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [debouncedSearchValue]);

  const search = () => {
    let filteredData;
    if (!debouncedSearchValue.trim().length) {
      filteredData = [...institutions];
    } else {
      filteredData = institutions.filter((institute) => {
        return institute.name
          .toLowerCase()
          .includes(debouncedSearchValue.toLowerCase());
      });
    }

    // TODO: Remove bank name comparison and switch to id key comparison (Recommended no to use toLowerCase())
    const uniqueNames = filteredData.reduce((acc, currentValue) => {
      if (
        !acc.some(
          (obj) =>
            obj.compare_name.toLowerCase() ===
            currentValue.compare_name.toLowerCase(),
        )
      ) {
        acc.push(currentValue);
      }
      return acc;
    }, []);
    setFilteredinstitutions(uniqueNames);
  };

  const itemTemplate = (item) => {
    const selected = item?.code === selectedInstitution?.code;
    return (
      <div
        className={`institution-list flex align-items-center ${
          selected ? "selected" : ""
        } ${item?.isRevokeAccess === false ? "disabled" : ""}`}
      >
        <div>{item.name}</div>
      </div>
    );
  };

  useEffect(() => {
    const nonRegisteredFiList = orgReducer?.allFiList?.filter(
      (item) => item?.isRegister === false,
    );

    const clubFiList = [
      ...orgReducer?.registeredFiList,
      ...nonRegisteredFiList,
    ];

    const sortedClubFiList = clubFiList.sort((a, b) =>
      a.compare_name.localeCompare(b.compare_name),
    );

    setinstitutions(sortedClubFiList);
  }, [orgReducer?.registeredFiList, orgReducer?.allFiList]);

  useEffect(() => {
    setSelectedInstitution(orgReducer?.selectedFI);
    setOnSelectedInstitution(true);
    // eslint-disable-next-line
  }, []);

  const focusOutToProfile = () => {
    setTimeout(() => {
      profileBoxRef &&
        profileBoxRef?.current &&
        profileBoxRef?.current?.focus();
    }, 250);
  };

  const onInstituteSelection = async (e) => {
    dispatch(resetHealthAnalysis());
    dispatch(setIsSFTPFileUploading(false));
    dispatch(setShowGaugeChart(false));
    dispatch(setIsPro(false));
    dispatch(setIsMarketing(false));

    if (e?.value?.tempIsPro === null) {
      dispatch(resetFHSSummary());
      dispatch(resetFIType(SUBSCRIPTION.NON_REGISTER_FI));
      const orgId = e.value.orgKey;
      const payload = {
        filter: {},
        requid: "",
        gte: "",
        lte: "",
        duration: "",
      };
      const fhsResponse = await dispatch(
        fetchFinancialHealthScoreSummary({ orgId, payload }),
      );
      dispatch(resetFHSExpanded());
      dispatch(setIsFHSReset(true));
      dispatch(
        setSelectedFI({
          ...e?.value,
          isBelogToCurrentReseller: true,
        }),
      );
      dispatch(setOrgKey(e?.value?.orgKey));
      dispatch(setOrgId(e?.value?.orguid));
      dispatch(setOrgName(e?.value?.org_name));
      setSelectedInstitution(e.value);
      dispatch(setOrgShortName(e?.value?.compare_name));
      if (fhsResponse?.payload?.status_code === 200) {
        // dispatch(fetchFinancialHealthScoreExpanded({ orgId, payload }));
        dispatch(allCohortThunk());
        dispatch(setHealthAnalysisID(null));
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        focusOutToProfile();
        navigate("/bank/health-analysis/report/no-analysis-id", {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      } else {
        dispatch(setHealthAnalysisID(null));
        dispatch(setIsFhsAvailable(false));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        focusOutToProfile();
        navigate("/user/nofhsdashboard");
        return null;
      }
    } else {
      if (e?.value?.tempIsPro === SUBSCRIPTION.FREEMIUM_WITHOUT_FHS) {
        dispatch(resetFIType(SUBSCRIPTION.FREEMIUM_WITHOUT_FHS));
        dispatch(setHealthAnalysisID(null));
        dispatch(resetFHSSummary());
        dispatch(resetFHSExpanded());
        dispatch(setIsFHSReset(true));
        dispatch(setSelectedFI(e?.value));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setIsFhsAvailable(false));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        focusOutToProfile();
        navigate("/user/nofhsdashboard");
        return null;
      } else if (e?.value?.tempIsPro === SUBSCRIPTION.FREEMIUM_WITH_FHS) {
        dispatch(resetFIType(SUBSCRIPTION.FREEMIUM_WITH_FHS));
        dispatch(setHealthAnalysisID(null));
        dispatch(resetFHSSummary());
        dispatch(resetFHSExpanded());
        dispatch(setIsFHSReset(true));
        dispatch(setSelectedFI(e?.value));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setOrgShortName(e?.value?.compare_name));
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        focusOutToProfile();
        navigate("/bank/health-analysis/report/no-analysis-id", {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      } else if (e?.value?.tempIsPro === SUBSCRIPTION.PRO) {
        dispatch(resetFIType(SUBSCRIPTION.PRO));
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(true));
        dispatch(setIsMarketing(false));
      } else if (e?.value?.tempIsPro === SUBSCRIPTION.MARKETING) {
        dispatch(resetFIType(SUBSCRIPTION.MARKETING));
        dispatch(setIsFhsAvailable(true));
        dispatch(setIsPro(true));
        dispatch(setIsMarketing(true));
      } else {
        dispatch(setHealthAnalysisID(null));
        dispatch(resetFHSSummary());
        dispatch(resetFHSExpanded());
        dispatch(setIsFHSReset(true));
        dispatch(setSelectedFI(e?.value));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setIsFhsAvailable(false));
        dispatch(setIsPro(false));
        dispatch(setIsMarketing(false));
        setOnSelectedInstitution(true);
        focusOutToProfile();
        navigate("/user/nofhsdashboard");
        return null;
      }

      const response = await dispatch(
        fetchHealthAnalysisSlice({
          tokenType: authReducer?.userData?.tokenType,
          token: authReducer?.userData?.token,
          orguid: e?.value?.orguid,
        }),
      );

      // File analysis loader for Reseler and Super Admin
      if (
        response.payload.data.length !== 0 &&
        [...getHealthAnalysisStatusList()].includes(
          response.payload.data[0]?.status,
        )
      ) {
        dispatch(setIsSFTPFileUploading(true));
      }

      let successfullUid = response.payload.data.find(
        (item) => item?.status?.toLowerCase() === "complete",
      );

      if (successfullUid) {
        dispatch(setHealthAnalysisID(successfullUid));
        dispatch(resetFHSSummary());
        dispatch(resetFHSExpanded());
        dispatch(
          setSelectedFI({
            ...e?.value,
            orgClientId: window.atob(e?.value?.orgClientId).slice(0, -1),
          }),
        );
        dispatch(setIsFHSReset(true));
        dispatch(setSkipAPICall(false));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setOrgShortName(e?.value?.compare_name));
        setOnSelectedInstitution(true);
        focusOutToProfile();
        navigate(`/bank/health-analysis/report/${successfullUid.uid}`, {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      } else {
        dispatch(setHealthAnalysisID(null));
        dispatch(resetFHSSummary());
        dispatch(resetFHSExpanded());
        dispatch(setSelectedFI(e?.value));
        dispatch(setOrgKey(e?.value?.orgKey));
        dispatch(setOrgId(e?.value?.orguid));
        dispatch(setOrgName(e?.value?.org_name));
        setSelectedInstitution(e.value);
        dispatch(setOrgShortName(e?.value?.compare_name));
        focusOutToProfile();
        navigate("/bank/health-analysis/report/no-analysis-id", {
          state: {
            orguid: e.value.orguid,
          },
        });
        return null;
      }
    }
  };

  return (
    <div className="institution-list card flex justify-content-center">
      <div className="p-inputgroup flex-1">
        {onSelectedInstitution && (
          <Button
            icon="pi pi-search"
            className="search"
            onFocus={() => {
              setSearchTyping("");
              setOnSelectedInstitution(false);
              autoCompleteRef.current.getInput().focus();
            }}
          />
        )}

        <AutoComplete
          field="name"
          ref={autoCompleteRef}
          value={onSelectedInstitution ? selectedInstitution : searchTyping}
          suggestions={filteredinstitutions}
          virtualScrollerOptions={{ itemSize: 38 }}
          placeholder="Search Institution"
          completeMethod={(event) => setSearchValue(event.query)}
          onChange={(e) => {
            if (
              e.originalEvent.type === "click" ||
              e.originalEvent.keyCode === 13
            ) {
              if (e.value.isRevokeAccess !== false) {
                dispatch(setPerformaLoading(true));
                dispatch(setSkipAPICall(true));
                setSearchTyping(e?.value?.compare_name);
                setOnSelectedInstitution(false);
                onInstituteSelection(e);
              }
            } else {
              setSearchTyping(e.value);
              setOnSelectedInstitution(false);
            }
          }}
          onFocus={(e) => {
            setSearchTyping(selectedInstitution?.compare_name);
            setOnSelectedInstitution(false);
            e.target.select();
          }}
          onBlur={() => {
            setSearchTyping("");
            setOnSelectedInstitution(true);
          }}
          itemTemplate={itemTemplate}
        />
      </div>
    </div>
  );
};

export default InstitutionList;
