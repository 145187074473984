import React from "react";
import "./RemovedInstitution.scss";
import { useSelector } from "react-redux";
import exportimages from "../../../assets/images/exportImages";

function CohortAdditionalRemovedInstitution({
    handleDelete,
    handleClearForRemovedInstitute,
}) {
    let removedDisplayFilterArr = useSelector(
        (state) => state?.cohortSlice?.removeDisplayAdditionalInstituteData,
    );
    return (
        <div className="removed-institution my-5">
            <div className="removed-institution-card">
                <div className="d-flex justify-content-between">
                    <div>
                        <h3>Removed Institutions</h3>
                    </div>
                    <div>
                        <button
                            className="btn btn-link text-clear text-muted p-0 cursor-pointer"
                            onClick={handleClearForRemovedInstitute}
                        >
                            Clear
                        </button>
                    </div>
                </div>
                <div>
                    Select institutions from this list that are not currently part of the
                    cohort.
                </div>
                <div className="mt-3" style={{ height: "10vh", overflowY: "auto" }}>
                    {removedDisplayFilterArr?.map((removedData, index) => {
                        return (
                            <div
                                key={removedData?.idrssd}
                                className="item-removed-wrapper d-flex justify-content-between align-item-center"
                            >
                                <p className="p-2 mb-0">{removedData?.institutionName}</p>
                                <button
                                    className="btn btn-link delete-icon me-2 py-auto"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(removedData);
                                    }}
                                >
                                    <img src={exportimages?.cohortremoveicon} alt="Delete" />
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default CohortAdditionalRemovedInstitution;
