import "./NoFHSReseller.scss";
import group_3 from "../../assets/images/in-progress/reseller_bank_img.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setOrgShortName } from "../../Redux/orgDetailsSlice";
import { SUBSCRIPTION } from "../../utils/global";
import { setPerformaLoading } from "../../Redux/loaderSlice";

export const NoFHSReseller = () => {
  const dispatch = useDispatch();
  const orgReducer = useSelector((state) => state.orgReducer);
  useEffect(() => {
    if (
      orgReducer?.selectedFI?.tempIsPro === SUBSCRIPTION.FREEMIUM_WITHOUT_FHS ||
      orgReducer?.selectedFI?.tempIsPro === null
    ) {
      dispatch(setOrgShortName(orgReducer?.selectedFI?.compare_name));
      dispatch(setPerformaLoading(false));
    }
  }, [orgReducer?.selectedFI]);

  return (
    <div className="noAnalysisBlock h-100">
      <div className=" text-center w-100  h-100 d-flex flex-column align-items-center justify-content-between">
        <div className="mb-2 mb-xxl-4 m-9 mt-5 " style={{ alignSelf: "start" }}>
          <div className="custom-theme pageTitle float-start w-100" id="">
            <h3 className="text-dark fs-1 fw-bolder lh-lg mb-0">{""}</h3>
          </div>
        </div>
        <div>
          <div className="col-md-12">
            <p
              className="blockTitle flex-grow-1 fs-1  mb-8"
              style={{ fontWeight: 900 }}
            >
              Welcome to the Autopilot!
            </p>
            <div className="col-md-12 mb-8">
              <img
                alt="logo"
                src={group_3}
                className="group_3"
                style={{ width: "28vw" }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <p className="mb-0 d-flex justify-content-center align-items-center fs-5 fs-xxl-6 ">
              <span style={{ width: "29%" }}>
                Regrettably, the financial institution you've chosen lacks
                adequate data at the moment to generate the Financial Health
                Score. Rest assured, our team is diligently working to analyze
                and compute precise scores for all institutions. Please revisit
                later to access the scores for this particular financial
                institution.
              </span>
            </p>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default NoFHSReseller;
