import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetFromServer from "../utils/GetFromServer";
import { store } from "../app/store";

const initialState = {
  drilldownCohort: null,
  loadingChanges: false,
};

export const fhsDrilldownCohortThunk = createAsyncThunk(
  "/api/api/cohortDrilldown",
  async ({ selectedCohort }, thunkAPI) => {
    try {
      const orguid =
        store.getState().orgReducer.selectedFI.orguid !== null
          ? store.getState().orgReducer.selectedFI.orguid
          : store.getState().orgReducer.selectedFI.orgKey;
      const response = await GetFromServer(
        `bank/${orguid}/cohort/data/${selectedCohort?.uid}?cohort_type=${selectedCohort?.cohort_type}`,
        {},
        {},
        {},
      );
      const cohort = { ...response.data.data[0], ...selectedCohort };
      return cohort;
    } catch (error) {
      console.log(error);
    }
  },
);
export const fhsDrilldownSlice = createSlice({
  name: "fhsDrilldownSlice",
  initialState,
  reducers: {
    // Add reset reducer
    resetState: (state) => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fhsDrilldownCohortThunk.pending, (state, action) => {
        state.loadingChanges = true;
      })
      .addCase(fhsDrilldownCohortThunk.fulfilled, (state, action) => {
        state.drilldownCohort = action.payload;
        state.loadingChanges = false;
      })
      .addCase(fhsDrilldownCohortThunk.rejected, (state, action) => {
        state.loadingChanges = false;
      });
  },
});

export const { resetState } = fhsDrilldownSlice.actions;

export default fhsDrilldownSlice.reducer;
