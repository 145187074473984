import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardname: "",
  cardPagePath: "",
  isPathFromCluster: false,
  healthAnalysisID: "",
};

export const navigationHistorySlice = createSlice({
  name: "navigationHistorySlice",
  initialState,
  reducers: {
    breadcrumHistory: (state, action) => {
      // TODO: Reconsider below code to be updated after refinement of Graph Component
      if (action.payload.cardName === "Opportunities") {
        state.cardname = "Uplift Loans";
      } else if (action.payload.cardName === "Secondary Consumer") {
        state.cardname = "Boost Deposits";
      } else {
        state.cardname = action.payload.cardName;
      }
      state.cardPagePath = action.payload.currentPath;
      state.isPathFromCluster = action.payload.checkPathFromCluster;
      state.healthAnalysisID = action.payload.hid;
    },

    checkExpandedCardDataLength: (state, action) => {
      const { cardName, cardDataLength } = action.payload;

      // Ensure state.cardData is initialized as an object
      if (!state.cardData || typeof state.cardData !== "object") {
        state.cardData = {};
      }

      // Check if the cardName already exists in cardData
      if (state.cardData[cardName]) {
        // If it exists, update the existing data
        state.cardData[cardName].cardDataLength = cardDataLength;
      } else {
        // If it doesn't exist, create a new entry
        state.cardData[cardName] = {
          cardname: cardName,
          cardDataLength: cardDataLength,
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { breadcrumHistory, checkExpandedCardDataLength } =
  navigationHistorySlice.actions;

export default navigationHistorySlice.reducer;
