import React, { useState, useEffect, useCallback } from "react";
import "./ConsumersListManagementPage.scss";
import "../themeTable.scss";
import { Link, useSearchParams } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderFalse, setLoaderTrue } from "../../../../Redux/loaderSlice";
import searchLogo from "../../../../assets/images/Search.png";
import { useLocation, useNavigate, useParams } from "react-router";
import exportimages from "../../../../assets/images/exportImages";
import GetFromServer from "../../../../utils/GetFromServer";
import { getFormattedDateType1 } from "../campaignConstant";
import moment from "moment/moment";

export const ConsumersDetailsPage = () => {
  const { campaignId, consumerListId } = useParams();
  const [searchParams] = useSearchParams();
  const isCampaignDetail = Boolean(searchParams.get("isCampaignDetail"));
  const [filteredConsumerList, setFilteredConsumerList] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filters] = useState({
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  const location = useLocation();
  const consumerListName = location?.state?.consumerListName;
  const [paginationObj, setPaginationObj] = useState({
    currentPage: 1,
    limit: 10,
    totalCustomerRecords: "",
    sortObj: { sortField: "", sortOrder: "DESC", sortOrderValue: -1 },
  });
  const [searchData, setSearchData] = useState("");
  const { currentPage, limit, totalCustomerRecords, sortObj } = paginationObj;
  const loader = useSelector((state) => state?.loaderReducer);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const orgReducer = useSelector((state) => state.orgReducer);
  const orgClientId = orgReducer?.selectedFI?.orgClientId;
  useEffect(() => {
    isCampaignDetail ? fetchCampaignConsumerList() : fetchAllConsumerListData();
  }, []);

  const fetchCampaignConsumerList = async () => {
    (async () => {
      dispatch(setLoaderTrue());
      try {
        let offset = (currentPage - 1) * 5;
        const response = await GetFromServer(
          `/${orgClientId}/campaign/${campaignId}/customer/${consumerListId}?searchby=&limit=10&offset=0&status=&orderby=DESC&field=CREATED_DATE`,
          {},
          {},
          {},
        );
        if (response.status === 200) {
          setFilteredConsumerList(response.data.data?.customers);
          setPaginationObj((prevState) => ({
            ...prevState,
            totalCustomerRecords: response?.data?.data?.totalCustomers,
          }));
          const newSerialNumbers = response?.data?.data?.customers.map(
            (item, index) => {
              return offset + index + 1;
            },
          );
          setSerialNumbers(newSerialNumbers);
        }
      } catch (error) {
      } finally {
        dispatch(setLoaderFalse());
      }
    })();
  };

  const fetchCampaignConsumerByPage = async (
    currentpagenumber,
    searchData,
    limit,
    sortValue,
  ) => {
    dispatch(setLoaderTrue());
    try {
      let offset = (currentpagenumber - 1) * limit;
      const response = await GetFromServer(
        `/${orgClientId}/campaign/${campaignId}/customer/${consumerListId}?searchby=${
          searchData ? encodeURIComponent(searchData) : encodeURIComponent("")
        }&limit=${limit}&offset=${offset}&status=&direction=${sortValue}&field=CREATED_DATE`,
        {},
        {},
        {},
      );
      if (response.status === 200) {
        setFilteredConsumerList(response?.data?.data?.customers);
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: currentpagenumber,
          totalCustomerRecords: response?.data?.data?.totalCustomers,
        }));
        const newSerialNumbers = response?.data?.data?.customers.map(
          (item, index) => {
            return offset + index + 1;
          },
        );
        setSerialNumbers(newSerialNumbers);
      }
    } catch (error) {
    } finally {
      dispatch(setLoaderFalse());
    }
  };

  const fetchAllConsumerListData = async () => {
    (async () => {
      dispatch(setLoaderTrue());
      try {
        let offset = (currentPage - 1) * 5;
        const response = await GetFromServer(
          `${orgClientId}/campaign/customer/${consumerListId}?searchby=&limit=10&offset=0&status=&orderby=DESC&field=CREATED_DATE`,
          {},
          {},
          {},
        );
        if (response.status === 200) {
          setFilteredConsumerList(response.data.data?.customers);
          setPaginationObj((prevState) => ({
            ...prevState,
            totalCustomerRecords: response?.data?.data?.totalCustomers,
          }));
          const newSerialNumbers = response?.data?.data?.customers.map(
            (item, index) => {
              return offset + index + 1;
            },
          );
          setSerialNumbers(newSerialNumbers);
        }
      } catch (error) {
      } finally {
        dispatch(setLoaderFalse());
      }
    })();
  };
  const fetchConsumerByPage = async (
    currentpagenumber,
    searchData,
    limit,
    sortValue,
  ) => {
    dispatch(setLoaderTrue());
    try {
      let offset = (currentpagenumber - 1) * limit;

      const response = await GetFromServer(
        `${orgClientId}/campaign/customer/${consumerListId}?searchby=${
          searchData ? encodeURIComponent(searchData) : encodeURIComponent("")
        }&limit=${limit}&offset=${offset}&status=&direction=${sortValue}&field=CREATED_DATE`,
        {},
        {},
        {},
      );
      if (response.status === 200) {
        setFilteredConsumerList(response?.data?.data?.customers);
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: currentpagenumber,
          totalCustomerRecords: response?.data?.data?.totalCustomers,
        }));
        const newSerialNumbers = response?.data?.data?.customers.map(
          (item, index) => {
            return offset + index + 1;
          },
        );
        setSerialNumbers(newSerialNumbers);
      }
    } catch (error) {
    } finally {
      dispatch(setLoaderFalse());
    }
  };
  const HeaderForTable = () => {
    return (
      <div className="row px-8 pt-6">
        <div className="col-xl-4 mb-6 mb-xxl-6">
          <h2 className="pb-2">{consumerListName}</h2>
          <div className="search-field w-100 w-sm-100 w-xs-100 position-relative">
            <div className="static-img ">
              <img src={searchLogo} alt="icon" className="searchicon" />
            </div>
            <input
              type="text"
              className="form-control custom-input-field"
              onChange={(e) => {
                optimizedSearchData(e.target.value);
              }}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    );
  };

  const templateForPaginator = {
    layout:
      "RowsPerPageDropdown  FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport",
    CurrentPageReport: (options) => {
      let totalPage = Math.ceil(totalCustomerRecords / limit);
      return (
        <span className="mr-5">
          {currentPage} of {totalPage}
        </span>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
        { label: 40, value: 40 },
        { label: 50, value: 50 },
      ];
      return (
        <div className="d-none d-md-none  d-sm-none d-xs-none  d-lg-flex ">
          <span className="Per-page">Per page</span>
          <Dropdown
            style={{ width: "auto" }}
            value={limit}
            options={dropdownOptions}
            onChange={handleDropdownValue}
            placeholder={"10"}
          />

          <span className="d-flex align-items-center">
            Displaying{" "}
            {loader?.loader
              ? ""
              : Math.min(currentPage * limit, totalCustomerRecords)}{" "}
            of {loader?.loader ? "" : totalCustomerRecords}
          </span>
        </div>
      );
    },
    PrevPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link ${
              currentPage === 1 ? "p-disabled" : ""
            }`}
            onClick={handlePrevPage}
            disabled={currentPage === 0}
          >
            <span className="p-paginator-icon pi pi-angle-left" />
          </button>
        </div>
      );
    },

    NextPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link  ${
              currentPage === Math.ceil(totalCustomerRecords / limit)
                ? "p-disabled"
                : ""
            }`}
            onClick={handleNextPage}
          >
            <span className="p-paginator-icon pi pi-angle-right" />
          </button>
        </div>
      );
    },
    FirstPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link ${
              currentPage === 1 ? "p-disabled" : ""
            }`}
            onClick={handleFirstPage}
            disabled={currentPage === 1}
          >
            <span className="p-paginator-icon pi pi-angle-double-left" />
          </button>
        </div>
      );
    },
    LastPageLink: (options) => {
      return (
        <div className="" style={{ padding: "0.5rem 1rem" }}>
          <button
            className={` p-paginator-element p-link ${
              currentPage === Math.ceil(totalCustomerRecords / limit)
                ? "p-disabled"
                : ""
            }`}
            onClick={handleLastPage}
            disabled={currentPage === Math.ceil(totalCustomerRecords / limit)}
          >
            <span className="p-paginator-icon pi pi-angle-double-right" />
          </button>
        </div>
      );
    },
    PageLinks: (options) => {
      if (options.totalPages === 1) {
        const pages = [];
        const maxPagesToShow = 3;

        let startPage = Math.max(
          1,
          currentPage - Math.floor(maxPagesToShow / 2),
        );
        let endPage = Math.min(
          Math.ceil(totalCustomerRecords / limit),
          startPage + maxPagesToShow - 1,
        );

        // Adjust startPage to ensure exactly maxPagesToShow links are shown
        if (endPage - startPage + 1 < maxPagesToShow) {
          startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        startPage = Math.max(1, startPage);
        for (let i = startPage; i <= endPage; i++) {
          pages.push(
            <button
              key={i}
              className={`p-paginator-element p-link p-paginator-page ${
                currentPage === i ? "p-highlight" : ""
              }`}
              onClick={() => handlePageChangeNew({ page: i })}
            >
              {i}
            </button>,
          );
        }
        return <div className="p-paginator-pages">{pages && pages}</div>;
      }
    },
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };
  const handleSearchData = (value) => {
    setSearchData(value);
    if (isCampaignDetail) {
      fetchCampaignConsumerByPage(1, value, limit, sortObj?.sortOrder);
    } else {
      fetchConsumerByPage(1, value, limit, sortObj?.sortOrder);
    }
  };

  const optimizedSearchData = useCallback(debounce(handleSearchData), [
    limit,
    sortObj,
    setSearchData,
    fetchCampaignConsumerByPage,
  ]);
  const handleDropdownValue = (value) => {
    setPaginationObj((prevState) => ({
      ...prevState,
      limit: value?.value,
    }));
    if (isCampaignDetail) {
      fetchCampaignConsumerByPage(
        1,
        searchData,
        value?.value,
        sortObj?.sortOrder,
      );
    } else {
      fetchConsumerByPage(1, searchData, value?.value, sortObj?.sortOrder);
    }
  };

  const handleLastPage = () => {
    if (isCampaignDetail) {
      let lastPage = Math.ceil(totalCustomerRecords / limit);
      if (lastPage > 0) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: lastPage,
        }));
        fetchCampaignConsumerByPage(
          lastPage,
          searchData,
          limit,
          sortObj?.sortOrder,
        );
      }
    } else {
      let lastPage = Math.ceil(totalCustomerRecords / limit);
      if (lastPage > 0) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: lastPage,
        }));
        fetchConsumerByPage(lastPage, searchData, limit, sortObj?.sortOrder);
      }
    }
  };
  const handleFirstPage = () => {
    if (isCampaignDetail) {
      setPaginationObj((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
      fetchCampaignConsumerByPage(1, searchData, limit, sortObj?.sortOrder);
    } else {
      setPaginationObj((prevState) => ({
        ...prevState,
        currentPage: 1,
      }));
      fetchConsumerByPage(1, searchData, limit, sortObj?.sortOrder);
    }
  };
  const handlePrevPage = () => {
    if (isCampaignDetail) {
      if (currentPage > 0) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: currentPage - 1,
        }));
        fetchCampaignConsumerByPage(
          currentPage - 1,
          searchData,
          limit,
          sortObj?.sortOrder,
        );
      }
    } else {
      if (currentPage > 0) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: currentPage - 1,
        }));
        fetchConsumerByPage(
          currentPage - 1,
          searchData,
          limit,
          sortObj?.sortOrder,
        );
      }
    }
  };
  const handleNextPage = () => {
    if (isCampaignDetail) {
      if (currentPage < Math.ceil(totalCustomerRecords / limit)) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: currentPage + 1,
        }));
        fetchConsumerByPage(
          currentPage + 1,
          searchData,
          limit,
          sortObj?.sortOrder,
        );
      }
    } else {
      if (currentPage < Math.ceil(totalCustomerRecords / limit)) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: currentPage + 1,
        }));
        fetchConsumerByPage(
          currentPage + 1,
          searchData,
          limit,
          sortObj?.sortOrder,
        );
      }
    }
  };

  const handlePageChangeNew = (event) => {
    if (isCampaignDetail) {
      if (event?.page !== currentPage) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: event?.page,
        }));
        fetchCampaignConsumerByPage(
          event?.page,
          searchData,
          limit,
          sortObj?.sortOrder,
        );
      }
    } else {
      if (event?.page !== currentPage) {
        setPaginationObj((prevState) => ({
          ...prevState,
          currentPage: event?.page,
        }));
        fetchConsumerByPage(event?.page, searchData, limit, sortObj?.sortOrder);
      }
    }
  };
  const emailDateBody = (rowData, index = null) => {
    if (rowData?.emailDate !== null && index !== null) {
      // # Convert string to datetime object
      return (
        <div>
          {rowData?.emailDate !== "NA"
            ? getFormattedDateType1(moment.utc(rowData?.emailDate).format())
            : "NA"}
        </div>
      );
    }
  };

  const emailStatusBody = (rowData) => {
    return (
      <div>
        {rowData?.emailStatus === "NA" ? "Pending" : rowData?.emailStatus}
      </div>
    );
  };
  const onSort = (field) => {
    if (isCampaignDetail) {
      let sortValue = field?.sortOrder === 1 ? "ASC" : "DESC";
      setPaginationObj((prevState) => ({
        ...prevState,
        sortObj: {
          sortField: field?.sortField,
          sortOrder: sortValue,
          sortOrderValue: field?.sortOrder,
        },
      }));
      fetchCampaignConsumerByPage(1, searchData, limit, sortValue);
    } else {
      let sortValue = field?.sortOrder === 1 ? "ASC" : "DESC";
      setPaginationObj((prevState) => ({
        ...prevState,
        sortObj: {
          sortField: field?.sortField,
          sortOrder: sortValue,
          sortOrderValue: field?.sortOrder,
        },
      }));
      fetchConsumerByPage(1, searchData, limit, sortValue);
    }
  };

  const srNumberBody = (rowData, index) => {
    return <div className="w-100">{serialNumbers[index?.rowIndex]}</div>;
  };

  return (
    <div
      className="px-10 pt-5 pb-10 contentWrapper dashboardContainer position-relative "
      id="alldata"
    >
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-md-5 col-sm-12  ">
          <div className="custom-theme pageTitle">
            <h3 className="text-dark fs-1 fw-bolder lh-lg mb-1 dashboard-title">
              Consumers List Details
            </h3>
          </div>
          <div
            className="custom-theme pageSubTitle text-868687 "
            style={{ fontSize: "12px" }}
          >
            <span>
              <Link
                id="graph_link_to_report"
                to={`/bank/campaign-management/email-campaigns`}
                style={{ color: "#868687" }}
              >
                Campaigns
              </Link>
              {" > "}
            </span>
            {isCampaignDetail ? (
              <span>
                <Link
                  id="graph_link_to_report"
                  to={`/bank/campaign-management/email-campaigns/${campaignId}`}
                  style={{ color: "#868687" }}
                >
                  Campaign Details
                </Link>
                {" > "}
              </span>
            ) : (
              <span>
                <Link
                  id="graph_link_to_report"
                  to={`/bank/campaign-management/consumers-list`}
                  style={{ color: "#868687" }}
                >
                  Consumers List
                </Link>
                {" > "}
              </span>
            )}
            <span className="text-4b4b4b text-decoration-underline">
              Consumers List Details
            </span>
          </div>
        </div>
        <div
          className="col-md-7 col-sm-12 text-end mb-4"
          style={{ fontWeight: "normal!important" }}
        >
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            onClick={() => {
              navigate(-1);
            }}
            style={{ marginRight: "10px" }}
            className="mr-4 btn btn-outline-primary border-primary btn-bond mb-2 mt-2 px-3 py-3 px-xxl-3 btn-lg fs-5  w-20"
          >
            <img
              alt="back-arrow"
              src={exportimages?.backArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImage"
            />
            <img
              alt="back-arrow"
              src={exportimages?.hoverBackArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImageHover"
            />
            <span
              className="me-3 fs-5 fs-xxl-6 "
              style={{ fontWeight: "normal" }}
            >
              Back{" "}
            </span>
          </button>
        </div>
      </div>
      <div
        className="row g-0 g-xl-5 g-xxl-8"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="row g-0 g-xl-5 g-xxl-8">
          <div className="col-md-12">
            <div className="mainContainer h-100 px-2">
              <DataTable
                value={filteredConsumerList}
                className="p-custom-datatable consumerDataTable sortDataTable h-100 "
                selectionMode="single"
                rows={filteredConsumerList?.length}
                paginator="true"
                reflow="true"
                paginatorTemplate={templateForPaginator}
                filters={filters}
                paginatorPosition="bottom"
                showcurrentpagereport="true"
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                currentPageReportTemplate="Displaying {last} of {totalRecords}"
                rowHover="true"
                scrollable="true"
                scrollHeight="flex"
                resizableColumns="true"
                responsiveLayout="scroll"
                size="large"
                globalFilterFields={["email"]}
                sortField={paginationObj?.sortObj?.sortField}
                sortOrder={paginationObj?.sortObj?.sortOrderValue}
                onSort={onSort}
                header={HeaderForTable}
                // onFilter={(e) => {
                //   setFilters(e.filters);
                // }}
                // onRowClick={(e) => setmodal(true)}
                emptyMessage={() => (
                  <h5 className="text-center pt-4 mt-1 mb-0 w-100">
                    <div className="col-md-12 mb-8">
                      <img src={exportimages.noDataFound} alt="no data" />
                    </div>
                    <div className="col-md-12">
                      <h5>There is no data to display at the moment.</h5>
                    </div>
                  </h5>
                )}
                // onSelectionChange={(e) => {
                //   setSelectedRowData(e.value);
                //   setmodal(true);
                // }}
              >
                <Column
                  className="ps-8"
                  header="Sr. No."
                  body={srNumberBody}
                  style={{ maxWidth: "6%", padding: "16px" }}
                />

                <Column
                  header="Email Address"
                  field={"emailId"}
                  style={{ maxWidth: "42%" }}
                />

                {!isCampaignDetail && (
                  <Column
                    header="Name"
                    field="name"
                    headerClassName={"ps-5"}
                    style={{ maxWidth: "45%", padding: "16px" }}
                  />
                )}
                {isCampaignDetail && (
                  <Column header="Name" field="name" headerClassName={"ps-5"} />
                )}

                {isCampaignDetail && (
                  <Column
                    header="Email Date"
                    field="emailDate"
                    body={emailDateBody}
                    headerClassName={"ps-2"}
                  />
                )}

                {isCampaignDetail && (
                  <Column
                    header="Email Status"
                    field="emailStatus"
                    body={emailStatusBody}
                    headerClassName={"ps-2"}
                  />
                )}

                {/* <Column
                  header="Email Status"
                  field="emailStatus"
                  body={(rowData) =>
                    rowData.emailStatus ? rowData.emailStatus : "Not Available"
                  }
                  headerClassName={"ps-4"}
                  style={{ maxWidth: "30%" }}
                  sortable
                /> */}
                {/* <Column
                  header="Date"
                  field="emailDate"
                  body={(rowData) =>
                    rowData.emailDate ? rowData.emailDate : "Not Available"
                  }
                  headerClassName={"ps-3"}
                  bodyClassName={"processStatusMainBlock px-1 py-1 pe-4"}
                  style={{ maxWidth: "30%", padding: "16px" }}
                  sortable
                /> */}
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsumersDetailsPage;
