import "./SetupInprogress.scss";
import { Card } from "primereact/card";
import Countdown, { zeroPad } from "react-countdown";
import Button from "react-bootstrap/Button";
import asset from "./../../../assets/images/in-progress/Asset_4_1.svg";
import constellation from "./../../../assets/images/in-progress/big_constellation.svg";
import ellipse_2511 from "./../../../assets/images/in-progress/ellipse_2511.svg";
import group_3 from "./../../../assets/images/in-progress/group_3.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setPerformaLoading } from "../../../Redux/loaderSlice";

const renderer = ({ hours, minutes, seconds }) => (
  <div className="d-flex countdown">
    <div className="hours">
      <div className="count">{zeroPad(hours)}</div>
      <div className="time">hours</div>
    </div>
    <span className="colon">:</span>
    <div className="minutes">
      <div className="count">{zeroPad(minutes)}</div>
      <div className="time">minutes</div>
    </div>
    <span className="colon">:</span>
    <div className="seconds">
      <div className="count">{zeroPad(seconds)}</div>
      <div className="time">seconds</div>
    </div>
  </div>
);

const SetupInprogress = () => {
  const authReducer = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const [countdowntime, setCountdowntime] = useState(
    Date.now() + 24 * 60 * 60 * 1000,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPerformaLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!authReducer) {
      navigate("/user/login");
    }

    const now = new Date(moment()).getTime();
    //   // const end = moment(authReducer?.userData?.createdOn);
    //   // const end = moment(now).subtract(120, "minutes");

    // const end = new Date(
    //   moment.utc("4/8/2024 9:27:11 AM"),
    // ).getTime();

    const momentDate = moment.utc(authReducer?.userData?.createdOn);
    const year = momentDate.year();
    const month = momentDate.month();
    const day = momentDate.date();
    const hour = momentDate.hour();
    const minute = momentDate.minute();
    const second = momentDate.second();

    const end = Date.UTC(year, month, day, hour, minute, second);

    //  countdowntime

    // console.log(authReducer?.userData?.createdOn);
    // console.log(end);
    if (end) {
      // var duration = moment.duration(now.diff(end));
      // const days = duration.asDays();
      // const countdown = end.add(24, "hours");
      // if (days <= 1) {
      //   setCountdowntime(new Date(countdown).getTime());
      // } else {
      //   setCountdowntime(new Date(now).getTime());
      // }
      const differenceInMilliseconds = now - end;
      if (differenceInMilliseconds > 24 * 60 * 60 * 1000) {
        // debugger;
        // If end date is more than 24 hours from now, set timer to zero
        setCountdowntime(Date.now());
      } else {
        // debugger;
        // Otherwise, set timer by subtracting hours from now date to end date
        // const millisecondsUntilEnd = end.diff(now);
        setCountdowntime(
          Date.now() + 24 * 60 * 60 * 1000 - differenceInMilliseconds,
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  const onStop = () => {
    alert("Test");
  };
  return (
    <div className="inprogress pt-10">
      <div className="container text-start">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-5">
              Your personalized analysis is currently in progress!
            </h2>
            <div className="mb-2 light">
              We're excited to inform you that your dashboard is currently being
              meticulously crafted to provide you with the most insightful
              analysis possible. Your dashboard will be accessible within one
              business day. We appreciate your patience as we complete the
              creation process.
            </div>
            <div className="mb-10">
              We'll notify you via email once your dashboard is ready for
              access.
            </div>
            <Countdown
              date={countdowntime}
              renderer={renderer}
              onStop={onStop}
            />

            <Card className="mb-10 p-0">
              <h5>
                Unlock exclusive features to enhance your banking experience.
              </h5>
              <div className="light mb-5">
                While your dashboard is being created, consider upgrading for
                premium insights and advanced analytics.
              </div>
              <Button
                className="login-registration-form btn btn-primary btn-bond loginTabIndex fw-bolder"
                style={{ letterSpacing: "0.7px", width: "16rem" }}
              >
                Upgrade
              </Button>
            </Card>
          </div>
          <div className="col-md-6 mt-20 position-absolute">
            <img alt="logo" src={asset} className="position-absolute asset" />
            <img
              alt="logo"
              src={constellation}
              className="position-absolute constellation"
            />
            <img
              alt="logo"
              src={ellipse_2511}
              className="position-absolute ellipse_2511"
            />
            <img
              alt="logo"
              src={group_3}
              className="position-absolute group_3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupInprogress;
