import React from "react";
import "./InstitutionUpdationList.scss";
import CohortAdditionalDisplayInstitution from "./CohortAdditionalDisplayInstitution";

const InstitutionUpdationList = React.forwardRef((props, ref) => {
  const {
    resetScrollPositionSelectedRemoveInstitution,
    visible,
    setVisible,
    containerHeight,
    inputValueInstitution,
    setInputValueInstitution,
    resetFlag,
    setResetFlag,
    setSelectedSortInstitution,
    selectedSortInstitution,
    showNotFound,
    setShowNotFound,
    resetScrollPositionSelectedInstitution,
    cardData,
    showInstitutionData,
  } = props;
  return (
    <div className="additional-cohort-management bg-white pt-5 h-100 px-5">
      <CohortAdditionalDisplayInstitution
        visible={visible}
        setVisible={setVisible}
        containerHeight={containerHeight}
        inputValueInstitution={inputValueInstitution}
        resetScrollPositionSelectedRemoveInstitution={
          resetScrollPositionSelectedRemoveInstitution
        }
        setInputValueInstitution={setInputValueInstitution}
        resetFlag={resetFlag}
        setResetFlag={setResetFlag}
        selectedSortInstitution={selectedSortInstitution}
        cardData={cardData}
        setSelectedSortInstitution={setSelectedSortInstitution}
        cardDisplay={props?.cardDisplay}
        showNotFound={showNotFound}
        setShowNotFound={setShowNotFound}
        ref={ref}
        resetScrollPositionSelectedInstitution={
          resetScrollPositionSelectedInstitution
        }
        showInstitutionData={showInstitutionData}
      />
    </div>
  );
});

export default InstitutionUpdationList;
