import { useState, createContext } from "react";
import { useDispatch } from "react-redux";
import {
  setIsFHSReset,
  setShowGaugeChart,
  setSkipFHSAPICall,
} from "../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import { opportunitiesAnalysisSlice } from "../rtk-query/features/dashboard/opportunities/opportunitiesAnalysisSlice";
import { secondaryConsumerAnalysisSlice } from "../rtk-query/features/dashboard/secondaryConsumer/secondaryConsumerAnalysisSlice";
import { missedRevenueAnalysisSlice } from "../rtk-query/features/dashboard/missedRevenue/missedRevenueAnalysisSlice";
import { bondScoreAnalysisSlice } from "../rtk-query/features/dashboard/bondScore/bondScoreAnalysisSlice";
import { topCompetitorsAnalysisSlice } from "../rtk-query/features/dashboard/topCompetitors/topCompetitorsAnalysisSlice";

export const ExitContext = createContext();

export const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    window.history.pushState({}, "", "/user/clearsession");
    if (showExitPrompt) {
      const e = event || window.event;

      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
  window.unload = function (event) {
    localStorage.clear();
    sessionStorage.clear();
  };
};

// Hook
export default function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const dispatch = useDispatch();

  window.onload = function () {
    dispatch(setSkipFHSAPICall(false));
    dispatch(setIsFHSReset(true));
    dispatch(setShowGaugeChart(false));
    dispatch(opportunitiesAnalysisSlice.util.resetApiState());
    dispatch(secondaryConsumerAnalysisSlice.util.resetApiState());
    dispatch(missedRevenueAnalysisSlice.util.resetApiState());
    dispatch(bondScoreAnalysisSlice.util.resetApiState());
    dispatch(topCompetitorsAnalysisSlice.util.resetApiState());
    initBeforeUnLoad(showExitPrompt);
  };

  return [showExitPrompt, setShowExitPrompt];
}
