import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFromServer } from "../utils/GetFromServer";
import { toast } from "react-toastify";
import { toastResponse } from "../Components/Common/Toast/Toast";
import { store } from "../app/store";
// import { setOrgId } from '../../../Redux/orgDetailsSlice'
// import { useDispatch } from 'react-redux';
//import { store } from '../../../app/storbhaRequestUide';

const initialState = {
  status: sessionStorage.getItem("SAVED_FILTERS") ? true : false,
  Opportunities: sessionStorage.getItem("SAVED_FILTERS")
    ? JSON.parse(sessionStorage.getItem("SAVED_FILTERS"))?.filter(
        (item) => item.cardName === "Opportunities",
      )
    : [],
  "Missing Product": sessionStorage.getItem("SAVED_FILTERS")
    ? JSON.parse(sessionStorage.getItem("SAVED_FILTERS"))?.filter(
        (item) => item.cardName === "Missing Product",
      )
    : [],
  "Ghost Consumer": sessionStorage.getItem("SAVED_FILTERS")
    ? JSON.parse(sessionStorage.getItem("SAVED_FILTERS"))?.filter(
        (item) => item.cardName === "Ghost Consumer",
      )
    : [],
  "Secondary Consumer": sessionStorage.getItem("SAVED_FILTERS")
    ? JSON.parse(sessionStorage.getItem("SAVED_FILTERS"))?.filter(
        (item) => item.cardName === "Secondary Consumer",
      )
    : [],
  top_competitor: sessionStorage.getItem("SAVED_FILTERS")
    ? JSON.parse(sessionStorage.getItem("SAVED_FILTERS"))?.filter(
        (item) => item.cardName === "Top competitor",
      )
    : [],
  selected_filter: null,
};

export const savedFilterThunk = createAsyncThunk(
  "/api/api/savedfilters",
  async (thunkAPI) => {
    try {
      const orguid = store.getState().orgReducer.orguid;
      const response = await GetFromServer(
        `/savedfilters?orguid=${orguid}`,
        {},
        {},
        {},
      );
      const sortedList = response.data.data
        ?.filter(
          (item) => !(item?.jsonForSelectedFilter).includes("filterZoneArr"),
        )
        ?.sort((a, b) => {
          return new Date(b.createdOn) - new Date(a.createdOn);
        });
      sessionStorage.setItem("SAVED_FILTERS", JSON.stringify(sortedList));
      return sortedList;
    } catch (error) {
      console.log(error);
    }
  },
);

export const savedFilterSlice = createSlice({
  name: "savedFilterSlice",
  initialState,
  reducers: {
    setSelectedFilterDetails: (state, action) => {
      state.selected_filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(savedFilterThunk.pending, (state, action) => {})
      .addCase(savedFilterThunk.fulfilled, (state, action) => {
        state["Opportunities"] = action.payload?.filter(
          (item) => item.cardName === "Opportunities",
        );
        state["Ghost Consumer"] = action.payload?.filter(
          (item) => item.cardName === "Ghost Consumer",
        );
        state["Secondary Consumer"] = action.payload?.filter(
          (item) => item.cardName === "Secondary Consumer",
        );
        state["Missing Product"] = action.payload?.filter(
          (item) => item.cardName === "Missing Product",
        );
        state.top_competitor = action.payload?.filter(
          (item) => item.cardName === "Top Competitor",
        );
        state.status = true;
      })
      .addCase(savedFilterThunk.rejected, (state, action) => {
        toast.error(toastResponse("error", action.error.message));
        sessionStorage.clear();
        sessionStorage.clear();
        state["Opportunities"] = [];
        state["Ghost Consumer"] = [];
        state["Secondary Consumer"] = [];
        state["Missing Product"] = [];
        state.top_competitor = [];
      });
  },
});

// export const { preSign} = preSign.actions
export const { savedFilter, setSelectedFilterDetails } =
  savedFilterSlice.actions;
export default savedFilterSlice.reducer;
