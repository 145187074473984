import axios from "axios";
import { store } from "../app/store";
import { LogOut } from "../Redux/authSlice";
import { globalError } from "./global-error-handler";
import { toast } from "react-toastify";
import { toastResponse } from "../Components/Common/Toast/Toast";

export const DeleteToServer = async (
  url,
  data = {},
  headers = {},
  params = {},
  logoutFlag = true,
) => {
  const state = store.getState();
  const authReducer = state.authReducer;
  axios.defaults.headers.common["Authorization"] =
    authReducer?.isLogin === false
      ? headers
      : `${authReducer?.userData?.tokenType?.toLowerCase()} ${
          authReducer?.userData?.token
        }`;
  const serverUrl = process.env.REACT_APP_API_URL;
  axios.defaults.baseURL = serverUrl;

  try {
    const response = await axios.delete(url, data, headers, params);

    return response;
  } catch (error) {
    if (error.response.status === 401) {
      store.dispatch(LogOut());
    } else {
      const errorDetails = globalError(
        error?.response
          ? error?.response
          : {
              data: {
                status: "ERROR",
                error: error?.code,
              },
            },
      );
      toast.error(toastResponse("error", errorDetails.message));
      return Promise.reject({ ...error.response, data: { ...errorDetails } });
    }
  }
};
