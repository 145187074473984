import React from "react";
import CheckboxComponent from "./CheckboxComponent";

function CohortAdditionalInstitution({
  onCategoryChange,
  checkedItems,
  loadingForInstitution,
}) {
  return (
    <CheckboxComponent
      arrayData={checkedItems}
      loadingForInstitution={loadingForInstitution}
      name="bankcategory"
      onChange={onCategoryChange}
      selectedCheckBoxValue={checkedItems}
    />
  );
}

export default CohortAdditionalInstitution;
