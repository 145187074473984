import React from "react";
import exportImages from "../../../assets/images/exportImages";
import "./CardDisplay.scss";
import {
  addSuffix,
  capitalizeFirstLetterOfEachWord,
} from "../report/widget/widgetsCommonFunction";

function CardDisplay({ data, cardDisplay }) {
  return (
    <>
      <div
        className="carddisplay"
        style={{
          backgroundColor: "#2C2C7C",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="card-content d-flex flex-column ps-5 pt-6">
          <div
            style={{
              color: "#2C2C7C",
              display: cardDisplay ? "none" : "block",
            }}
          >
            <br />
            <br />
            <br />
          </div>
          {cardDisplay ? (
            <h2
              className="ps-2 text-break me-10 pe-10"
              style={{ color: "white", marginBottom: "10px" }}
            >
              {data?.name ? capitalizeFirstLetterOfEachWord(data?.name) : ""}
            </h2>
          ) : (
            <h2
              className="ps-2"
              style={{ color: "white", marginBottom: "30px" }}
            >
              {""}
            </h2>
          )}
          <div className="d-flex">
            {cardDisplay ? (
              <p
                className="ps-2 mb-0 cohort-description text-break"
                style={{ color: "white", flex: "1" }}
              >
                {data?.description ? data?.description : ""}
              </p>
            ) : (
              <p
                className="ps-2 mb-0 cohort-description"
                style={{ color: "white", flex: "1" }}
              >
                {""}
              </p>
            )}
            <img
              src={exportImages?.cohort_icon}
              alt="error"
              height="95px"
              width="140px"
              style={{ marginLeft: "20px", marginTop: "-15px" }}
            />
          </div>
        </div>
        <hr
          style={{
            border: "none",
            borderTop: "3px solid #3434C6",
            margin: "0px",
          }}
        />
      </div>
      {cardDisplay && (
        <div className="additional-div px-6 py-5">
          <div
            className="d-flex justify-content-between"
            style={{ color: "white" }}
          >
            <div className="d-flex">
              <div className="image-container me-2">
                <img
                  src={exportImages?.FHS_percentile}
                  alt="loading"
                  height="24px"
                />
              </div>
              <div className="me-1">
                Percentile{" "}
                <div className="">
                  {data?.hasOwnProperty("percentile")
                    ? addSuffix(Math.round(data?.percentile))
                    : 0}
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="image-container me-2">
                <img
                  src={exportImages?.cohort_average}
                  alt="loading"
                  height="19px"
                />
              </div>
              <div className="me-1" style={{ fontSize: "12px" }}>
                Cohort Average{" "}
                <div className="">
                  {data?.hasOwnProperty("cohort_avg")
                    ? Number(Math.round(data?.cohort_avg))
                    : 0}
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="image-container me-2">
                <img
                  src={exportImages?.FHS_institution}
                  alt="loading"
                  height="24px"
                />
              </div>
              <div>
                Institutions{" "}
                <div className="">
                  {data?.hasOwnProperty("institution_count")
                    ? data?.institution_count
                    : 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CardDisplay;
