import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetFromServer from "../../../utils/GetFromServer";

const initialState = {
  healthAnalysis: null,
  isSFTPFileUploading: false,
  currentHealthAnalysis: null,
};

export const fetchHealthAnalysisSlice = createAsyncThunk(
  `fetch_health_analysis`,
  async ({ tokenType, token, orguid }, thunkAPI) => {
    try {
      const response = await GetFromServer(
        "/bank/healthanalysis",
        {},
        { Authorization: `${tokenType?.toLowerCase()} ${token}` },
        { orguid: orguid, type: "none" },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.data.error);
    }
  },
);

export const recursiveCallAnalysisStatus = createAsyncThunk(
  `recursive_call_analysis_stauts`,
  async ({ orguid, uid }, thunkAPI) => {
    try {
      const response = await GetFromServer(
        `/bank/healthanalysis/${uid}?orguid=${orguid}&lang=en_us&source=website`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.data.error);
    }
  },
);

const healthAnalysisSlice = createSlice({
  name: "health_analysis",
  initialState,
  reducers: {
    setIsSFTPFileUploading: (state, action) => {
      state.isSFTPFileUploading = action.payload;
    },
    // Add the reset action to reset the state to initial values
    resetHealthAnalysis: (state) => {
      state.healthAnalysis = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHealthAnalysisSlice.fulfilled, (state, action) => {
        state.healthAnalysis = {
          ...state.healthAnalysis,
          payload: action.payload,
          isLoading: false,
        };
      })
      .addCase(fetchHealthAnalysisSlice.pending, (state, action) => {
        state.healthAnalysis = {
          isLoading: true,
        };
      })
      .addCase(recursiveCallAnalysisStatus.fulfilled, (state, action) => {
        state.currentHealthAnalysis = {
          ...state.currentHealthAnalysis,
          payload: action.payload,
          isLoading: false,
        };
      })
      .addCase(recursiveCallAnalysisStatus.pending, (state, action) => {
        state.currentHealthAnalysis = {
          isLoading: true,
        };
      });
  },
});

export const selectHealthAnalysis = (state) =>
  state?.healthAnalysisReducer?.healthAnalysis?.payload?.data;

export const selectIsSFTPFileUploading = (state) =>
  state?.healthAnalysisReducer?.isSFTPFileUploading;

export const { setIsSFTPFileUploading, resetHealthAnalysis } =
  healthAnalysisSlice.actions;

export default healthAnalysisSlice.reducer;
