import React, { useState } from "react";
import "./CohortDisplayData.scss";
import { Modal } from "react-bootstrap";
import exportimages from "../../../assets/images/exportImages";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedIndex } from "../../../Redux/cohortSlice";
import {
  addSuffix,
  capitalizeFirstLetterOfEachWord,
} from "../report/widget/widgetsCommonFunction";

function CohortDisplayData(props) {
  const { id, name, uid, description } = props?.datas;
  const [showModal, setShowModal] = useState(false);
  const [previousSelectedIndex, setPreviousSelectedIndex] = useState(null); // Track the previous selected index

  let selectedIndex = useSelector((state) => state?.cohortSlice?.selectedIndex);

  let cohortData = JSON?.parse(props?.datas?.cohort_data); 

  let dispatch = useDispatch();
  const handleOpenModal = () => {
    setPreviousSelectedIndex(selectedIndex);
    setShowModal(true);
  };
  const handleCloseModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
    // Restore the previous selected index
    if (previousSelectedIndex) {
      dispatch(setSelectedIndex(previousSelectedIndex));
    }
  };

  return (
    <>
      <div
        key={id}
        className="cohort-card cohort-display-data px-6 cursor-pointer"
        style={{ borderRadius: "0px" }}
        onClick={()=>props?.handleCohortData(props?.datas)}
      >
        <div className="mb-2">
          <h5 className="font-color text-break">
            {capitalizeFirstLetterOfEachWord(name)}
          </h5>
        </div>
        <div className="mb-2 text-break">
          <p>{description}</p>
          <div className="d-flex mb-4 justify-content-between">
            <div>
              <h6 className="small font-color">Percentile</h6>
              <div>
                {cohortData?.percentile
                  ? addSuffix(Math.round(cohortData?.percentile))
                  : 0}
              </div>
            </div>
            <div>
              <h6 className="small font-color">Cohort Average</h6>
              <div>
                {cohortData?.cohort_avg
                  ? Number(Math.round(cohortData?.cohort_avg))
                  : 0}
              </div>
            </div>
            <div>
              <h6 className="small font-color">Institutions</h6>
              <div>
                {cohortData?.institution_count
                  ? cohortData?.institution_count
                  : 0}
              </div>
            </div>
          </div>

          <div className="d-flex mt-3 mb-5 justify-content-between align-items-center">
            <div className="d-flex">
              {/* <div className="me-1">
                                <img src={logo1} alt="icon" />
                            </div>
                            <div className="me-1">
                                <img src={logo1} alt="icon" />
                            </div>
                            <div>
                                <img src={logo1} alt="icon" />
                            </div> */}
            </div>
            <div className="d-flex justify-content-start">
              <div className="me-3 cursor-pointer">
                <img src={exportimages?.cohortediticon} alt="err" />
              </div>
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal();
                }}
              >
                <img src={exportimages?.cohortdeleteicon} alt="err" />
              </div>

              {/* <div>
                            <img src={exportimages?.cohortsorticon} alt="err" />
                        </div> */}
              {/* <div className="rounded-circle d-flex justify-content-center button-size align-items-center p-0 m-0" onClick={() => handleOpenModal(id)}>
                            <Button className="custom-button" icon="pi pi-trash" size={24} />
                        </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="diviser" />

      {showModal && (
        <Modal show={showModal} size="md" centered onClick={(e) => e.stopPropagation()} >
          <Modal.Header className="m-3 py-4 border-0 px-5">
            <div className="d-flex justify-content-between w-100">
              <div>
                <h4>Delete Cohort</h4>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseModal}
              >
                {""}
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="m-3 py-1 px-5">
            <p className="mb-3">
              Are you certain you want to delete this cohort?
            </p>
            <h5>Cohort Name</h5>
            <p>{props?.datas?.name}</p>

            <div className="border-top-0 py-5 d-flex justify-content-between">
              <button
                type="submit"
                className="btn btn-secondary btn-bond fw-bolder fs-5 fs-xxl-6  py-4 me-10 w-100"
                onClick={(e) => {
                  handleCloseModal(e);
                }}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary btn-bond fw-bolder fs-5 fs-xxl-6  py-4 w-100"
                onClick={(e) => {
                  e.stopPropagation();
                  props.handleCohortDeleteItem(e, uid); //NOSONAR
                  handleCloseModal(e);
                }}
              >
                Delete Cohort
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default CohortDisplayData;
