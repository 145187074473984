import { isRejected } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastResponse } from "../../Components/Common/Toast/Toast";
import { globalError } from "../../utils/global-error-handler";

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // isRejectedWithValue Or isRejected
  if (isRejected(action)) {
    if (
      Number.isInteger(action.payload?.status) &&
      action.payload?.status !== 200
    ) {
      // TODO : Need to change the status code comparion with the global constants
      if (
        action.payload?.status !== 400 &&
        action.payload?.status !== 401 &&
        action.payload?.status !== 404
      ) {
        toast.error(
          toastResponse(
            "error",
            globalError({
              data: {
                data: "",
                error: action?.payload?.status,
                message: action?.payload?.error,
                status: "ERROR",
              },
            }).message,
          ),
        );
      }
    }
  }

  return next(action);
};
