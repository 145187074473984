import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { putToServer } from "../utils/putToServer";
import { setCookie } from "../utils/Utils";
import moment from "moment/moment";

const initialState = {
  userData: sessionStorage.getItem("userData")
    ? JSON.parse(sessionStorage.getItem("userData"))
    : {},
  isLogin: sessionStorage.getItem("isLogin") ? true : false,
  token: sessionStorage.getItem("token") ?? null,
  expiresIn: sessionStorage.getItem("expiresIn") ?? null,
  expirationTime: sessionStorage.getItem("expirationTime") ?? null,
  createdOn: sessionStorage.getItem("createdOn") ?? null,
  isTempPro: sessionStorage.getItem("isTempPro") ?? null,
  banklist: [],
  userContactedSales: false,
};

// THUNK API CALL FOR LOGOUT
export const LogOut = createAsyncThunk(
  "/auth/logout",
  async (arg = {}, thunkAPI) => {
    try {
      const response = await putToServer(
        `/auth/logout?lang=en_us&source=website`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.data.error);
    }
  },
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    login: (state, action) => {
      state.userData = action.payload;
      state.isLogin = true;
      state.token = action.payload?.token;
      state.expiresIn = action.payload?.expiresIn;
      state.expirationTime = moment()
        .add(action.payload?.expiresIn, "seconds")
        .format();
      state.createdOn = action.payload?.createdOn;
      sessionStorage.setItem("userData", JSON.stringify(state.userData));
      sessionStorage.setItem("isLogin", state.isLogin);
      sessionStorage.setItem("token", state.token);
      sessionStorage.setItem("expiresIn", state.expiresIn);
      sessionStorage.setItem("expirationTime", state.expirationTime);
      sessionStorage.setItem("createdOn", state.createdOn);
      setCookie("isLogin", state.isLogin, 1);
    },
    setIsTempPro: (state, action) => {
      state.isTempPro = action.payload;
    },
    setFirstName: (state, action) => {
      state.userData.firstName = action.payload;
      sessionStorage.setItem("userData", JSON.stringify(state.userData));
    },
    setLastName: (state, action) => {
      state.userData.lastName = action.payload;
      sessionStorage.setItem("userData", JSON.stringify(state.userData));
    },
    setBankList: (state, action) => {
      state.banklist = action.payload;
    },
    logOut: (state) => {
      state.userData = {};
      state.isLogin = false;
    },
    setUserContactedSales: (state, action) => {
      state.userContactedSales = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LogOut.pending, (state, action) => {})
      .addCase(LogOut.fulfilled, (state, action) => {
        sessionStorage.clear();
        state.userData = {};
        state.isLogin = false;
        state.token = null;
      })
      .addCase(LogOut.rejected, (state, action) => {
        sessionStorage.clear();
        state.userData = {};
        state.isLogin = false;
        state.token = null;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logOut,
  setIsTempPro,
  setFirstName,
  setLastName,
  setBankList,
  setUserContactedSales,
} = authSlice.actions;

export default authSlice.reducer;
