import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthLayout } from "../Components/AuthLayout";
// import { EmailVerified, Forgot, Login, OtherEmail, Register, Reset, Faq, Settings, StepOne, StepTwo, Graph, StepThree, MainReport, List, UserManagementList, UserManagementRegister, FinancialHealthScore } from '../Components/Components'
import "react-toastify/dist/ReactToastify.css";
import "../Components/Common/Toast/toast.component.css";

import { useSelector } from "react-redux";

import { Suspense, lazy, useEffect, useState } from "react";
import { Fallback } from "../Components/Common/Fallback";
import ConsumersDetailsPage from "../Components/Bank/CampaignManagement/ConsumersWorkFlow/ConsumersDetailsPage";
import { getCookie } from "../utils/Utils";
import CampaignPrivateRoutes from "./CampaignPrivateRoutes";
import SetupInprogress from "../Components/Auth/setupInprogress/SetupInprogress";
import NoBankDashboard from "../Components/NoBankDashboard/NoBankDashboard";
import NoFHSReseller from "../Components/NoFHSReseller/NoFHSReseller";
import { SUBSCRIPTION } from "../utils/global";
import CohortManagement from "../Components/Bank/CohortManagement/CohortManagement";

export const NavigatingRoutes = () => {
  const authReducer = useSelector((state) => state.authReducer);
  const orgReducer = useSelector((state) => state.orgReducer);
  const navigate = useNavigate();
  const MainReport = lazy(
    () =>
      import(
        /* webpackChunkName: "MainReport" */ "../Components/Bank/report/main/MainReport"
      ),
  );
  const Dashboard = lazy(
    () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../Components/Dashboard/Dashboard"
      ),
  );
  const RestrictedRoutes = lazy(
    () =>
      import(/* webpackChunkName: "RestrictedRoutes" */ "./RestrictedRoutes"),
  );
  const PrivateRoutes = lazy(
    () => import(/* webpackChunkName: "PrivateRoutes" */ "./PrivateRoutes"),
  );
  const History = lazy(
    () =>
      import(
        /* webpackChunkName: "History" */ "../Components/Bank/History/History"
      ),
  );
  const AdminRestrictedRoutes = lazy(
    () =>
      import(
        /* webpackChunkName: "AdminRestrictedRoutes" */ "./AdminRestrictedRoutes"
      ),
  );
  const FileUploadPrivateRoute = lazy(
    () =>
      import(
        /* webpackChunkName: "FileUploadPrivateRoute" */ "./FileUploadPrivateRoute"
      ),
  );
  // const UserManagementPrivateRoute = lazy(
  //   () =>
  //     import(
  //       /* webpackChunkName: "UserManagementPrivateRoute" */ "./UserManagementPrivateRoute"
  //     ),
  // );
  const FinancialHealthScoreDetailedView = lazy(
    () =>
      import(
        /* webpackChunkName: "FinancialHealthScoreDetails" */ "../Components/Bank/report/widget/FinancialHealthScore/FinancialHealthScoreDetails/FinancialHealthScoreDetails"
      ),
  );
  const Settings = lazy(
    () =>
      import(
        /* webpackChunkName: "Settings" */ "../Components/settings/Settings"
      ),
  );
  const EmailVerified = lazy(
    () =>
      import(
        /* webpackChunkName: "EmailVerified" */ "../Components/Auth/email-verified/EmailVerified"
      ),
  );
  const Forgot = lazy(
    () =>
      import(
        /* webpackChunkName: "Forgot" */ "../Components/Auth/forgot/Forgot"
      ),
  );
  const Login = lazy(
    () =>
      import(/* webpackChunkName: "Login" */ "../Components/Auth/login/Login"),
  );
  const LoginSessionClear = lazy(
    () =>
      import(
        /* webpackChunkName: "Login" */ "../Components/Auth/login/LoginSessionClear"
      ),
  );
  const OtherEmail = lazy(
    () =>
      import(
        /* webpackChunkName: "OtherEmail" */ "../Components/Auth/other-email/OtherEmail"
      ),
  );
  const Reset = lazy(
    () =>
      import(/* webpackChunkName: "Reset" */ "../Components/Auth/reset/Reset"),
  );
  const Register = lazy(
    () =>
      import(
        /* webpackChunkName: "Register" */ "../Components/Auth/register/Register"
      ),
  );
  const Faq = lazy(
    () => import(/* webpackChunkName: "Faq" */ "../Components/Common/faq/Faq"),
  );
  const StepThree = lazy(
    () =>
      import(
        /* webpackChunkName: "StepThree" */ "../Components/Bank/wizard/stepThree/StepThree"
      ),
  );
  const StepTwo = lazy(
    () =>
      import(
        /* webpackChunkName: "StepTwo" */ "../Components/Bank/wizard/stepTwo/StepTwo"
      ),
  );
  const StepOne = lazy(
    () =>
      import(
        /* webpackChunkName: "StepOne" */ "../Components/Bank/wizard/stepOne/StepOne"
      ),
  );
  const UserManagementRegister = lazy(
    () =>
      import(
        /* webpackChunkName: "UserManagementRegister" */ "../Components/Auth/user-management-register/UserManagementRegister"
      ),
  );
  const Graph = lazy(
    () =>
      import(
        /* webpackChunkName: "Graph" */ "../Components/Bank/report/drilldown/graph/Graph"
      ),
  );
  const List = lazy(
    () =>
      import(
        /* webpackChunkName: "List" */ "../Components/Bank/report/drilldown/list/List"
      ),
  );
  const UserManagementList = lazy(
    () =>
      import(
        /* webpackChunkName: "UserManagementList" */ "../Components/Bank/usermanagement/UserManagementList"
      ),
  );
  const ConsumersListManagementPage = lazy(
    () =>
      import(
        /*webpackChunkName: "ConsumersWorkFlow"*/ "../Components/Bank/CampaignManagement/ConsumersWorkFlow/ConsumersListManagementPage"
      ),
  );

  const CampaignManagementPage = lazy(
    () =>
      import(
        /*webpackChunkName: "CampaignWorkFlow"*/ "../Components/Bank/CampaignManagement/CampaignWorkFlow/CampaignManagementPage"
      ),
  );

  const CreateCampaign = lazy(
    () =>
      import(
        /*webpackChunkName: "CampaignWorkFlow"*/ "../Components/Bank/CampaignManagement/CampaignWorkFlow/CreateCampaign"
      ),
  );

  const UpdateEmailTemplate = lazy(
    () =>
      import(
        /*webpackChunkName: "CampaignWorkFlow"*/ "../Components/Bank/CampaignManagement/CampaignWorkFlow/UpdateEmailTemplate"
      ),
  );

  const EditEmail = lazy(
    () =>
      import(
        /*webpackChunkName: "CampaignWorkFlow"*/ "../Components/Bank/CampaignManagement/CampaignWorkFlow/EditEmail"
      ),
  );

  const ReviewEmail = lazy(
    () =>
      import(
        /*webpackChunkName: "CampaignWorkFlow"*/ "../Components/Bank/CampaignManagement/CampaignWorkFlow/ReviewEmail"
      ),
  );

  const TemplateManagementPage = lazy(
    () =>
      import(
        /*webpackChunkName: "TemplateWorkFlow"*/ "../Components/Bank/CampaignManagement/TemplateWorkFlow/TemplateManagementPage"
      ),
  );

  const CreateTemplate = lazy(
    () =>
      import(
        /*webpackChunkName: "TemplateWorkFlow"*/ "../Components/Bank/CampaignManagement/TemplateWorkFlow/CreateTemplate"
      ),
  );

  const UpdateTemplate = lazy(
    () =>
      import(
        /*webpackChunkName: "TemplateWorkFlow"*/ "../Components/Bank/CampaignManagement/TemplateWorkFlow/UpdateTemplate"
      ),
  );

  const CampaignDetailsPage = lazy(
    () =>
      import(
        /*webpackChunkName: "TemplateWorkFlow"*/ "../Components/Bank/CampaignManagement/CampaignWorkFlow/CampaignDetailsPage"
      ),
  );

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const healthAnalysisID = orgReducer?.healthAnalysisID?.uid;

  useEffect(() => {
    if (authReducer.isLogin === true || getCookie("isLogin") !== "") {
      if (
        !window.location.href.includes("bank") &&
        !window.location.href.includes("pages") &&
        healthAnalysisID &&
        !window.location.href.includes("clearsession") &&
        !window.location.href.includes("login") &&
        !window.location.href.includes("register") &&
        !window.location.href.includes("forgot") &&
        !window.location.href.includes("reset") &&
        !window.location.href.includes("email-verified")
      ) {
        if (
          orgReducer?.selectedFI?.tempIsPro !==
            SUBSCRIPTION.FREEMIUM_WITHOUT_FHS &&
          orgReducer?.selectedFI?.tempIsPro !== SUBSCRIPTION.FREEMIUM_WITH_FHS
        )
          navigate(`/bank/health-analysis/report/${healthAnalysisID}`);
      }
    }
    /* eslint-disable-next-line */
  }, [authReducer.isLogin, healthAnalysisID]);

  return (
    <>
      <AuthLayout>
        {isOnline ? (
          <Suspense fallback={<Fallback />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/user/login" element={<Login />} />
              <Route
                path="/user/clearsession"
                element={<LoginSessionClear />}
              />
              <Route element={<RestrictedRoutes />}>
                <Route path="/user/login/:key" element={<Login />} />
                <Route path="/user/register" element={<Register />} />
                <Route path="/user/forgot" element={<Forgot />} />
                <Route path="/user/other-user" element={<OtherEmail />} />
                <Route path="/user/reset/" element={<Reset />} />
                <Route
                  path="/user/email-verified"
                  element={<EmailVerified />}
                />
                <Route path="/login/:key" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/other-user" element={<OtherEmail />} />
                <Route path="/reset/" element={<Reset />} />
                <Route path="/email-verified" element={<EmailVerified />} />
                <Route
                  path="/user/user-management-register"
                  element={<UserManagementRegister />}
                />
              </Route>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/user/setup-inprogress"
                  element={<SetupInprogress />}
                />
                <Route
                  path="/bank/health-analysis/history"
                  element={<History />}
                />
                <Route path="/pages/faq" element={<Faq />} />
                <Route path="/pages/faq/:accordianid" element={<Faq />} />
                <Route path="/pages/settings" element={<Settings />} />
                <Route element={<AdminRestrictedRoutes />}>
                  <Route element={<FileUploadPrivateRoute />}>
                    <Route path="/pages/stepone" element={<StepOne />} />
                    <Route path="/pages/steptwo" element={<StepTwo />} />
                    <Route path="/pages/stepthree" element={<StepThree />} />
                  </Route>
                  <Route
                    path="/user/dashboard"
                    element={
                      authReducer?.userData?.role === "User" ||
                      authReducer?.userData?.role === "Bond-Admin" ? (
                        <Dashboard />
                      ) : (
                        <Dashboard />
                      )
                    }
                  />
                  <Route
                    path="/user/nobankdashboard"
                    element={
                      authReducer?.userData?.role === "Reseller" ||
                      authReducer?.userData?.role === "Bond-Admin" ? (
                        <NoBankDashboard />
                      ) : (
                        <></>
                      )
                    }
                  />
                  <Route
                    path="/user/nofhsdashboard"
                    element={
                      authReducer?.userData?.role === "Reseller" ||
                      authReducer?.userData?.role === "Bond-Admin" ? (
                        <NoFHSReseller />
                      ) : (
                        <></>
                      )
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      authReducer?.userData?.role === "User" ||
                      authReducer?.userData?.role === "Bond-Admin" ? (
                        <Dashboard />
                      ) : (
                        <StepOne />
                      )
                    }
                  />
                  <Route
                    path="/"
                    element={
                      authReducer?.userData?.role === "User" ||
                      authReducer?.userData?.role === "Bond-Admin" ? (
                        <Dashboard />
                      ) : authReducer.isLogin === true &&
                        healthAnalysisID ? null : (
                        <StepOne />
                      )
                    }
                  />
                  <Route path="/user/graph" element={<Graph />} />
                </Route>
                <Route
                  path="/bank/health-analysis/report/:hid"
                  element={<MainReport />}
                />
                <Route
                  path="/bank/health-analysis/report/:hid/financial-health-score"
                  element={<FinancialHealthScoreDetailedView />}
                />
                <Route
                  path="/bank/health-analysis/report/:hid/drilldown/list/:type"
                  element={<List />}
                />
                <Route
                  path="/bank/health-analysis/report/:hid/drilldown/graph/:type"
                  element={<Graph />}
                />
                <Route element={<CampaignPrivateRoutes />}>
                  <Route
                    path="/bank/campaign-management/consumers-list"
                    element={<ConsumersListManagementPage />}
                  />
                  <Route
                    path="/bank/campaign-management/consumers-list/:campaignId"
                    element={<ConsumersListManagementPage />}
                  />
                  <Route
                    path="/bank/campaign-management/consumers-list-details/:consumerListId"
                    element={<ConsumersDetailsPage />}
                  />
                  <Route
                    path="/bank/campaign-management/consumers-list-details/:campaignId/:consumerListId"
                    element={<ConsumersDetailsPage />}
                  />
                  <Route
                    path="/bank/campaign-management/email-campaigns"
                    element={<CampaignManagementPage />}
                  />
                  <Route
                    path="/bank/campaign-management/email-campaigns/:campaignId"
                    element={<CampaignDetailsPage />}
                  />
                  <Route
                    path="/bank/campaign-management/create-campaign"
                    element={<CreateCampaign />}
                  />
                  <Route
                    path="/bank/campaign-management/edit-campaign/:id"
                    element={<EditEmail />}
                  />
                  <Route
                    path="/bank/campaign-management/update-email-template/:id"
                    element={<UpdateEmailTemplate />}
                  />
                  <Route
                    path="/bank/campaign-management/review-email/:id"
                    element={<ReviewEmail />}
                  />
                  <Route
                    path="/bank/campaign-management/email-templates"
                    element={<TemplateManagementPage />}
                  />
                  <Route
                    path="/bank/campaign-management/create-template"
                    element={<CreateTemplate />}
                  />
                  <Route
                    path="/bank/campaign-management/update-template/:id"
                    element={<UpdateTemplate />}
                  />
                </Route>
                <Route
                  path="/bank/user-management/user-management-list"
                  element={<UserManagementList />}
                />
                <Route
                  path="/bank/cohort-management"
                  element={<CohortManagement />}
                />
              </Route>
            </Routes>
          </Suspense>
        ) : (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <div className="fs-3">No internet connection.</div>
          </div>
        )}
      </AuthLayout>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="light"
        limit={3}
        style={{
          display: "flex",
          position: "absolute",
        }}
      />
    </>
  );
};
