import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetFromServer from "../utils/GetFromServer";
import { store } from "../app/store";

const initialState = {
  allCohort: [],
};

export const allCohortThunk = createAsyncThunk(
  "/api/api/allCohort",
  async (thunkAPI) => {
    try {
      const orguid =
        store.getState().orgReducer.selectedFI.orguid !== null
          ? store.getState().orgReducer.selectedFI.orguid
          : store.getState().orgReducer.selectedFI.orgKey;
      const response = await GetFromServer(`bank/${orguid}/cohort`, {}, {}, {});
      const cohortList = response.data.data;
      return cohortList;
    } catch (error) {
      console.log(error);
    }
  },
);
export const allCohortSlice = createSlice({
  name: "allCohortSlice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(allCohortThunk.pending, (state, action) => {
      })
      .addCase(allCohortThunk.fulfilled, (state, action) => {
        state.allCohort = action.payload;
      })
      .addCase(allCohortThunk.rejected, (state, action) => {
      });
  },
});

export default allCohortSlice.reducer;
