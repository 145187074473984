import "./NoBankDashboard.scss";
import group_3 from "../../assets/images/in-progress/reseller_bank_img.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPerformaLoading } from "../../Redux/loaderSlice";

export const NoBankDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPerformaLoading(false));
    /* eslint-disable-next-line */
  }, []);
  return (
    <div className="noAnalysisBlock h-100">
      <div className=" text-center w-100  h-100 d-flex flex-column align-items-center justify-content-between">
        <div className="row mb-2 mb-xxl-4 m-9 mt-5">
          <div className="col-md-12">
            <p
              className="blockTitle flex-grow-1 fs-1  mb-8"
              style={{ fontWeight: 900 }}
            >
              Welcome to the Autopilot!
            </p>
            <div className="col-md-12 mb-8">
              <img
                alt="logo"
                src={group_3}
                className="group_3"
                style={{ width: "28vw" }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <p className="mb-0 d-flex justify-content-center align-items-center fs-5 fs-xxl-6 ">
              <span style={{ width: "29%" }}>
                Please take a moment to view the selection of financial
                institutions listed in the Institution menu option above. Once
                you've made your choice, you'll unlock a range of
                functionalities, including the ability to access the Financial
                Health Score specific to your chosen institution and extend
                invitations to other users as needed.
              </span>
            </p>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default NoBankDashboard;
