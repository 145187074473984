import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostToServer } from "../utils/PostToServer";
import store from "../app/store";

export const PostDataByCohortFilter = createAsyncThunk(
  "cohort/fetchDataOfInstitution",
  async (filterData, thunkAPI) => {
    try {
      const orguid = store.getState().orgReducer.selectedFI?.orguid;
      const response = await PostToServer(
        `bank/${orguid}/cohort/filter?card=cohort&view=filter&searchby=${encodeURIComponent(
          "",
        )}&sortby=${encodeURIComponent("")}&orderby=${encodeURIComponent(
          "",
        )}&page=0&size=0`,
        filterData,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

const initialState = {
  assetSize: { min: "", max: "" },
  selectedStates: [],
  filteredCities: [],
  selectedCategory: "",
  checkedItems: [],
  institutionData: [],
  displayInstitutionData: [],

  showAdditionalInstitutionData: [],
  originalData: [],
  displayAdditionalInsitituteData: [],
  removeDisplayAdditionalInstituteData: [],
  selectedIndex: null,
  selectedAllData: [],
  unSelectedAllData: [],
};

const cohortSlice = createSlice({
  name: "cohort",
  initialState,
  reducers: {
    setSelectStates: (state, action) => {
      state.selectedStates = action.payload;
    },
    setFilterCities: (state, action) => {
      state.filteredCities = action.payload;
    },
    setSelectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setAssetSize: (state, action) => {
      state.assetSize = action.payload;
    },
    setDisplayInstitution: (state, action) => {
      state.displayInstitutionData = action.payload;
    },
    setRemovedInstitution: (state, action) => {
      state.removedInstitution = action.payload;
    },
    setCheckedInstitutionItems: (state, action) => {
      state.checkedItems = action.payload;
    },
    setShowAdditionalInstitutionData: (state, action) => {
      state.showAdditionalInstitutionData = action.payload;
    },
    setAdditionalInstitutionData: (state, action) => {
      state.institutionData = action.payload;
    },
    setOriginalData: (state, action) => {
      state.originalData = action.payload;
    },
    setDisplayAdditionalInsitituteData: (state, action) => {
      state.displayAdditionalInsitituteData = action.payload;
    },
    setRemoveDisplayAdditionalInsitituteData: (state, action) => {
      state.removeDisplayAdditionalInstituteData = action.payload;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },

    setSelectedAllData: (state, action) => {
      state.selectedAllData = action.payload;
    },

    setUnselectedAllData: (state, action) => {
      state.unSelectedAllData = action.payload;
    },
    resetCohortData: (state, action) => {
      state.displayAdditionalInsitituteData = [];
      state.removeDisplayAdditionalInstituteData = [];
      state.showAdditionalInstitutionData = [];
      state.assetSize = { min: "", max: "" };
      state.selectedStates = [];
      state.selectedCategory = "";
      state.checkedItems = [];
      state.institutionData = [];
      state.selectedIndex = null;
      state.selectedAllData = [];
      state.originalData = [];
      state.unSelectedAllData = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(PostDataByCohortFilter.pending, (state, action) => {})
      .addCase(PostDataByCohortFilter.fulfilled, (state, action) => {
        if (action.payload?.data && action.payload.data.length > 0) {
          const [data] = action.payload.data;
          let tempData = data?.unselected?.map((data) => {
            return {
              ...data,
              value: data?.idrssd,
              label: data?.institutionName,
            };
          });
          state.institutionData = tempData || [];
          state.removeDisplayAdditionalInstituteData = data?.removed || [];
          state.showAdditionalInstitutionData = data?.added || [];
          state.displayAdditionalInsitituteData = data?.selected || [];
          state.originalData = data?.selected || [];
          state.selectedAllData = data?.selected_idrssd || [];
          state.unSelectedAllData = data?.unselected_idrssd || [];
          state.checkedItems = data?.added || [];
        }
      })
      .addCase(PostDataByCohortFilter.rejected, (state, action) => {});
  },
});

export const {
  setSelectedAllData,
  setAssetSize,
  setSelectedIndex,
  setSelectStates,
  resetCohortData,
  setDisplayAdditionalInsitituteOpData,
  setDisplayAdditionalInsitituteData,
  setRemoveDisplayAdditionalInsitituteData,
  setFilterCities,
  setShowAdditionalInstituteOpData,
  setAdditionalInstitutionData,
  setSelectCategory,
  setGroupedDisplayInstitution,
  setDisplayInstitution,
  setAddedInstitution,
  setRemovedInstitution,
  setCheckedInstitutionItems,
  setShowAdditionalInstitutionData,
  setUnselectedAllData,
  setCountData,
  setOriginalData,
} = cohortSlice.actions;

export default cohortSlice.reducer;
