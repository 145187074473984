import { Checkbox } from "primereact/checkbox";
import React from "react";

function CheckboxComponent(props) {
  const {
    arrayData,
    onChange,
    selectedCheckBoxValue,
    name,
    loadingForInstitution,
  } = props;
  return (
    <>
      {arrayData?.map((state) => {
        //NOSONAR
        return (
          <div key={state?.idrssd} className="checkbox pt-3">
            <Checkbox
              inputId={state?.idrssd}
              name={name}
              className="pt-1"
              value={state}
              onChange={onChange}
              checked={selectedCheckBoxValue?.some(
                //NOSONAR
                (item) => item?.idrssd === state?.idrssd,
              )}
              disabled={loadingForInstitution}
            />
            <label
              htmlFor={state?.idrssd}
              className="checkboxLabel font-color form-check-label fw-bold cursor-pointer ms-2"
            >
              {state?.name ? state?.name : state?.institutionName}
            </label>
          </div>
        );
      })}
    </>
  );
}

export default CheckboxComponent;
