import axios from "axios";
import { store } from "../app/store";
import { LogOut } from "../Redux/authSlice";
import { globalError } from "./global-error-handler";
import { toast } from "react-toastify";
import { toastResponse } from "../Components/Common/Toast/Toast";
import errormessages from "../assets/i18n/en.json";

export const PostToServer = async (
  url,
  data = {},
  headers = {},
  params = {},
  logoutFlag = true,
) => {
  const state = store.getState();
  const authReducer = state.authReducer;

  axios.defaults.headers.common["Authorization"] =
    authReducer?.isLogin === false
      ? headers
      : `${authReducer?.userData?.tokenType?.toLowerCase()} ${
          authReducer?.userData?.token
        }`;
  const serverUrl = process.env.REACT_APP_API_URL;
  axios.defaults.baseURL = serverUrl;
  try {
    if (navigator.onLine) {
      const response = await axios.post(url, data, headers, params);

      return response;
    } else {
      toast.error(
        toastResponse(
          "error",
          "There seems to be issues with internet connectivity. Please check your connection.",
        ),
      );
    }
  } catch (error) {
    if (error.response.status === 401) {
      store.dispatch(LogOut());
    } else {
      const errorDetails = globalError(
        error?.response
          ? error?.response
          : {
              data: {
                status: "ERROR",
                error: error?.code,
              },
            },
      );
      if (error.response.status === 401) {
        toast.error(toastResponse("error", errormessages.ERROR.CODE[401]));
      } else {
        toast.error(toastResponse("error", errorDetails.message));
      }
      return Promise.reject({ ...error.response, data: { ...errorDetails } });
    }
  }
};

export const PostToServerNew = async (
  url,
  data = {},
  headers = {},
  params = {},
  logoutFlag = true,
) => {
  const state = store.getState();
  const authReducer = state.authReducer;

  axios.defaults.headers.common["Authorization"] =
    authReducer?.isLogin === false
      ? headers
      : `${authReducer.userData.tokenType.toLowerCase()} ${
          authReducer?.userData?.token
        }`;
  const serverUrl = process.env.REACT_APP_API_URL;
  axios.defaults.baseURL = serverUrl;

  try {
    // data = {
    //    "filter": {},
    //    "gte": "2021-01-01",
    //    "lte": "2021-12-31",
    //    "duration": ""
    // };
    if (navigator.onLine) {
      const response = await axios.post(url, data, headers, params);

      return response;
    } else {
      toast.error(
        toastResponse(
          "error",
          "There seems to be issues with internet connectivity. Please check your connection.",
        ),
      );
    }
  } catch (error) {
    if (error.response.status === 401) {
      store.dispatch(LogOut());
    } else {
      const errorDetails = globalError(error.response);
      return Promise.reject({ ...error.response, data: { ...errorDetails } });
    }
  }
};
