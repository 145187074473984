import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "./App.scss";
import chmln from "@chamaeleonidae/chmln";
import { NavigatingRoutes } from "./routes/Routes";
import { useChameleonModalContext } from "./Context/ChameleonModal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useExitPrompt, { ExitContext } from "./utils/useExitPrompt";
import { deleteAllCookies } from "./utils/Utils";

const App = () => {
  const chameleonContext = useChameleonModalContext();
  const authReducer = useSelector((state) => state.authReducer);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-HRN7TTHQ9N");
    }

    return deleteAllCookies();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        chameleonContext.setChameleonValue(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (authReducer?.isLogin) {
      const chmlnFlag = process.env.REACT_APP_CHAMELEON_IS_ENABLE;

      // console.log(process.env.REACT_APP_CHAMELEON_IS_ENABLE);
      if (chmlnFlag === "true") {
        //initialize chameleon
        chmln.init(process.env.REACT_APP_CHAMELEON_API_Key);
        localStorage.setItem("chameleonInitialized", "true");
        //send user data to chameleon
        chmln.identify(
          `${authReducer.userData.orguid}${authReducer.userData.useruid}`,
          {
            // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")/* chameleon.io user identification and data */
            email: authReducer.userData.userName,
            role: authReducer.userData?.role, // RECOMMENDED, email is used as the key to map user data for integrations
          },
        );
      }
    }
  }, [authReducer]);

  return (
    <ExitContext.Provider value={{ showExitPrompt, setShowExitPrompt }}>
      <NavigatingRoutes />
    </ExitContext.Provider>
  );
};

export default App;
