import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import CheckboxComponent from "./CheckboxComponent";
import { Card } from "primereact/card";
import searchLogo from "../../../assets/images/Search.png";
import "./CohortFilter.scss";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { toast } from "react-toastify";
import { toastResponse } from "../../Common/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderFalse, setLoaderTrue } from "../../../Redux/loaderSlice";
import {
  PostDataByCohortFilter,
  setAssetSize,
  setFilterCities,
  setSelectCategory,
  setSelectStates,
} from "../../../Redux/cohortSlice";
import { selectFinancialHealthScoreExpanded, selectFinancialHealthScoreSummary } from "../../../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";

function updateTextViews(value) {
  const sanitizedData = sanitizeValue(value);
  const num = parseFloat(sanitizedData);
  if (num === 0) {
    return 0;
  }
  if (isNaN(num)) {
    return "";
  }
  return num.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  });
}

function sanitizeValue(value) {
  let sanitizedValueData = value.replace(/[^0-9.]/g, "");

  const decimalIndex = sanitizedValueData.indexOf(".");

  if (decimalIndex !== -1) {
    sanitizedValueData =
      sanitizedValueData.substring(0, decimalIndex + 1) +
      sanitizedValueData.substring(decimalIndex + 1).replace(/\./g, "");
  }
  return sanitizedValueData;
}

const CohortFilter = ({
  saveOperation,
  error,
  setError,
  setShowNotFound,
  resetScrollPositionSelectedInstitution,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [cities, setCities] = useState([]);
  const [searchState,setSearchState]=useState("");
  const [showMaxValue, setShowMaxValue] = useState(null);
  const [showMinValue, setShowMinValue] = useState(null);

  let dispatch = useDispatch();
  let allCohortSliceObj = useSelector((state) => state?.cohortSlice);


  const { payload: FHSExpanded } = useSelector((state) =>
    selectFinancialHealthScoreSummary(state),
  );
  const [assetSizeValue, setAssetSizeValue] = useState({ min: "", max: "" });

  useEffect(() => {
    setAssetSizeValue({
      min: allCohortSliceObj?.assetSize?.min || "",
      max: allCohortSliceObj?.assetSize?.max || "",
    });

    allCohortSliceObj?.assetSize?.min >= 0
      ? setShowMinValue(
          updateTextViews(allCohortSliceObj?.assetSize?.min?.toString()),
        )
      : setShowMinValue("");
    allCohortSliceObj?.assetSize?.max >= 0
      ? setShowMaxValue(
          updateTextViews(allCohortSliceObj?.assetSize?.max?.toString()),
        )
      : setShowMaxValue("");
  }, [allCohortSliceObj]);
  let usCity = [
    { name: "Alabama", idrssd: "alabama" },
    { name: "Alaska", idrssd: "alaska" },
    { name: "Arizona", idrssd: "arizona" },
    { name: "Arkansas", idrssd: "arkansas" },
    { name: "California", idrssd: "california" },
    { name: "Colorado", idrssd: "colorado" },
    { name: "Connecticut", idrssd: "connecticut" },
    { name: "Delaware", idrssd: "delaware" },
    { name: "Florida", idrssd: "florida" },
    { name: "Georgia", idrssd: "georgia" },
    { name: "Hawaii", idrssd: "hawaii" },
    { name: "Idaho", idrssd: "idaho" },
    { name: "Illinois", idrssd: "illinois" },
    { name: "Indiana", idrssd: "indiana" },
    { name: "Iowa", idrssd: "iowa" },
    { name: "Kansas", idrssd: "kansas" },
    { name: "Kentucky", idrssd: "kentucky" },
    { name: "Louisiana", idrssd: "louisiana" },
    { name: "Maine", idrssd: "maine" },
    { name: "Maryland", idrssd: "maryland" },
    { name: "Massachusetts", idrssd: "massachusetts" },
    { name: "Michigan", idrssd: "michigan" },
    { name: "Minnesota", idrssd: "minnesota" },
    { name: "Mississippi", idrssd: "mississippi" },
    { name: "Missouri", idrssd: "missouri" },
    { name: "Montana", idrssd: "montana" },
    { name: "Nebraska", idrssd: "nebraska" },
    { name: "Nevada", idrssd: "nevada" },
    { name: "New Hampshire", idrssd: "newhampshire" },
    { name: "New Jersey", idrssd: "newjersey" },
    { name: "New Mexico", idrssd: "newmexico" },
    { name: "New York", idrssd: "newyork" },
    { name: "North Carolina", idrssd: "northcarolina" },
    { name: "North Dakota", idrssd: "northdakota" },
    { name: "Ohio", idrssd: "ohio" },
    { name: "Oklahoma", idrssd: "oklahoma" },
    { name: "Oregon", idrssd: "oregon" },
    { name: "Pennsylvania", idrssd: "pennsylvania" },
    { name: "Rhode Island", idrssd: "rhodeisland" },
    { name: "South Carolina", idrssd: "southcarolina" },
    { name: "South Dakota", idrssd: "southdakota" },
    { name: "Tennessee", idrssd: "tennessee" },
    { name: "Texas", idrssd: "texas" },
    { name: "Utah", idrssd: "utah" },
    { name: "Vermont", idrssd: "vermont" },
    { name: "Virginia", idrssd: "virginia" },
    { name: "Washington", idrssd: "washington" },
    { name: "West Virginia", idrssd: "westvirginia" },
    { name: "Wisconsin", idrssd: "wisconsin" },
    { name: "Wyoming", idrssd: "wyoming" },
  ];
  useEffect(() => {
    dispatch(setFilterCities(usCity));
    setCities(usCity);
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(setSelectCategory(FHSExpanded?.fi_type));
  }, [dispatch, FHSExpanded]);

  const onStatesChange = (e) => {
    let selectedStates = [...(allCohortSliceObj?.selectedStates || [])];
    if (e.checked) selectedStates.push(e.value);
    else
      selectedStates = selectedStates.filter(
        (state) => state.name !== e.value.name,
      );
    dispatch(setSelectStates(selectedStates));
  };
  const validateAssetSize = (assetSize) => {
    const minAssetSize =
      assetSize?.min >= 0 ? parseFloat(Number(assetSize?.min)) : "";
    const maxAssetSize =
      assetSize?.max >= 0 ? parseFloat(Number(assetSize?.max)) : "";

    if (minAssetSize === "" && maxAssetSize === "") {
      return "";
    }
    if (minAssetSize > maxAssetSize) {
      return "Minimum asset size cannot be greater than maximum asset size.";
    }
    return "";
  };

  function handleDropdownToggle(e) {
    e.preventDefault();
    setShowOptions((prevShowOptions) => !prevShowOptions);
    e.stopPropagation();
  }

  const dropdownRef = useRef(null);
  const dropdownIconRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !inputRef?.current?.contains(event.target) &&
        !dropdownRef?.current?.contains(event.target) &&
        !dropdownIconRef?.current?.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    e.preventDefault();
    const filtered = cities?.filter((city) =>
      city?.name?.toLowerCase().includes(e?.target?.value.toLowerCase()),
    );
    dispatch(setFilterCities(filtered));
    setShowOptions(true);
    setSearchState(e.target.value)
  };
  const categoriesArr = [
    { name: "Bank", key: "BK" },
    { name: "Credit Union", key: "CU" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowNotFound(true);

    const removedIds =
      allCohortSliceObj?.removeDisplayAdditionalInstituteData?.map((item) =>
        parseInt(item?.idrssd, 10),
      ) || [];

    const addedIds =
      allCohortSliceObj?.checkedItems?.map((item) =>
        parseInt(item?.idrssd, 10),
      ) || [];

    const selectedIds =
      allCohortSliceObj?.originalData?.map((item) =>
        parseInt(item?.idrssd, 10),
      ) || [];

    const unselectedIds =
      allCohortSliceObj?.institutionData?.map((item) =>
        parseInt(item?.idrssd, 10),
      ) || [];

    const minAssetSize = parseFloat(assetSizeValue.min) || 0;
    const maxAssetSize = parseFloat(assetSizeValue.max) || 0;
    const transformedObject = {
      filter: {
        assets: {
          min: minAssetSize,
          max: maxAssetSize,
        },
        state: allCohortSliceObj?.selectedStates?.map((state) => state.name),
        type: FHSExpanded?.fi_type,
        institutions: {
          selected: selectedIds,
          added: saveOperation === "Create" ? [] : addedIds,
          removed: saveOperation === "Create" ? [] : removedIds,
          unselected: unselectedIds,
        },
      },
    };
    resetScrollPositionSelectedInstitution();

    try {
      dispatch(setLoaderTrue());
      const response = await dispatch(
        PostDataByCohortFilter(transformedObject),
      );
      if (response.payload?.status === "SUCCESS") {
        toast.success(toastResponse("success", "Applied Filter Successfully"));
        setSearchState("")
        dispatch(setLoaderFalse());
      }

    } catch (error) {
      dispatch(setLoaderFalse());
    } finally {
      dispatch(setLoaderFalse());
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = sanitizeValue(value);
    setAssetSizeValue((prev) => {
      const updatedAssetSize = { ...prev, [name]: sanitizedValue };
      const validationError = validateAssetSize(updatedAssetSize);
      setError(validationError);
      return updatedAssetSize;
    });

    if (name === "min") {
      setShowMinValue(updateTextViews(sanitizedValue));
      dispatch(setAssetSize({ ...allCohortSliceObj?.assetSize, min: sanitizedValue }));
    } else if (name === "max") {
      setShowMaxValue(updateTextViews(sanitizedValue));
      dispatch(setAssetSize({ ...allCohortSliceObj?.assetSize, max: sanitizedValue }));
    }
  };
  const isFilterValid = () => {
    let validAssetSize = validateAssetSize(assetSizeValue);
    let assetSizeValidation;
    const minAssetSize = allCohortSliceObj?.assetSize?.min
      ? parseFloat(Number(allCohortSliceObj?.assetSize?.min))
      : "";
    const maxAssetSize = allCohortSliceObj?.assetSize?.max
      ? parseFloat(Number(allCohortSliceObj?.assetSize?.max))
      : "";
    if (isNaN(minAssetSize) || isNaN(maxAssetSize)) {
      return true;
    }
    if (
      allCohortSliceObj?.selectedStates?.length === 0 &&
      (allCohortSliceObj?.assetSize?.min === "" ||
        allCohortSliceObj?.assetSize?.max === "")
    ) {
      assetSizeValidation = true;
    } else if (allCohortSliceObj?.selectedStates?.length > 0) {
      if (
        allCohortSliceObj?.assetSize?.min === "" &&
        allCohortSliceObj?.assetSize?.max !== ""
      ) {
        return true;
      }
    } else {
      assetSizeValidation = validAssetSize?.length > 0;
    }
    const isStateValid =
      allCohortSliceObj?.selectedStates.length > 0 ? false : true;
    return assetSizeValidation && isStateValid;
  };
  return (
    <div className="cohort-filter rounded bg-white py-7">
      <div className="px-7">
        <h3 className="mb-2">Filters</h3>
        <section className="filter">
          <div className="header">
            <h5 className="filter-title">Asset Size</h5>
            <button
              className="btn btn-link link text-muted text-clear cursor-pointer"
              onClick={() => {
                setAssetSizeValue({ min: "", max: "" });
                let assetobj = { min: "", max: "" };
                dispatch(setAssetSize(assetobj));
                setError("");
                setShowMinValue("");
                setShowMaxValue("");
              }}
            >
              Clear
            </button>
          </div>
          <div className="sub-title">
            Cohort's average asset size in $45,892 (Dollar amounts in thousands)
          </div>
          <div className="main-body d-flex">
            <InputText
              type="text"
              variant="filled"
              className="asset-size flex-1"
              placeholder="Min Value"
              value={showMinValue}
              maxLength={21}
              name="min"
              onChange={(e) => {                
                handleChange(e);
              }}
              style={{ backgroundColor: "#F5F8FA" }}
            />
            <InputText
              type="text"
              variant="filled"
              className="asset-size flex-1"
              placeholder="Max Value"
              name="max"
              value={showMaxValue}
              maxLength={21}
              onChange={(e) => {
                handleChange(e);
              }}
              style={{ backgroundColor: "#F5F8FA" }}
            />
          </div>
          {error && <p className="error-message text-red-500">{error}</p>}
        </section>
      </div>
      <div className="diviser" />
      <section className="filter px-7">
        <div className="header">
          <h5 className="filter-title">State</h5>
          <button
            className="btn btn-link link text-muted text-clear cursor-pointer"
            onClick={() => {setSearchState("");dispatch(setSelectStates([]));dispatch(setFilterCities(usCity))}}
          >
            Clear
          </button>
        </div>
        <div className="main-body">
          <div className="checkbox-list flex flex-column gap-3">
            <div className="search-field w-100 mt-3 w-sm-100 w-xs-100 position-relative">
              <div className="static-img">
                <img src={searchLogo} alt="icon" className="searchicon" />
              </div>
              <input
                ref={inputRef}
                type="text"
                className="login-registration-form form-control custom-input-field"
                placeholder="Search State"
                value={searchState}
                onFocus={(e) => {
                  setShowOptions(true);
                  e.stopPropagation();
                }}
                onChange={handleSearchChange}
              />
              <button
                ref={dropdownIconRef}
                className="pi pi-chevron-down text-muted cursor-pointer dropdown-icon"
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "42%",
                  background: "none",
                  border: "none",
                  padding: 0,
                }}
                onClick={handleDropdownToggle}
                aria-label="Toggle Dropdown"
              />

              {showOptions && (
                <div
                  ref={dropdownRef}
                  className="card-dropdown w-100"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    zIndex: 100,
                  }}
                >
                  <div>
                    <Card style={{ maxHeight: "200px", overflowY: "auto" }}>
                      {allCohortSliceObj?.filteredCities?.length > 0 ? (
                        <CheckboxComponent
                          arrayData={allCohortSliceObj?.filteredCities}
                          onChange={(e) => {
                            onStatesChange(e);
                          }}
                          name="state"
                          selectedCheckBoxValue={
                            allCohortSliceObj?.selectedStates
                          }
                        />
                      ) : (
                        <p className="mb-0">No data found</p>
                      )}
                    </Card>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`${allCohortSliceObj?.selectedStates?.length > 0 ? "state-height" : ""}`}
            >
              <CheckboxComponent
                arrayData={allCohortSliceObj?.selectedStates}
                onChange={onStatesChange}
                name="state"
                selectedCheckBoxValue={allCohortSliceObj?.selectedStates}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="diviser" />
      <section className="filter px-7">
        <div className="header">
          <h5 className="filter-title">FI Type</h5>
        </div>
       
        <div className="flex flex-column gap-3">
          {categoriesArr.map((category) => {
            return (
              <div key={category.key} className="flex align-items-center mt-3">
                <RadioButton
                  className="me-3"
                  inputId={category.key}
                  name="category"
                  onChange={(e) => {
                    dispatch(setSelectCategory(FHSExpanded?.fi_type));
                  }}
                  checked={FHSExpanded?.fi_type === category.key}
                  disabled
                />
                <label htmlFor={category.key} className="ml-2">
                  {category.name}
                </label>
              </div>
            );
          })}
        </div>

        <div className="d-flex justify-content-end mt-10 pt-6">
          <Button
            type="submit"
            className="btn apply-filter"
            onClick={handleSubmit}
            disabled={isFilterValid()}
          >
            Apply Filters
          </Button>
        </div>
      </section>
    </div>
  );
};

export default CohortFilter;
