import React from "react";
import searchLogo from "../../../assets/images/Search.png";
import { Form } from "react-bootstrap";
import "./CohortManagement.scss"

function CohortSearchComponent({
  searchData,
  setSearchData,
  handleFilterSearchData,
}) {
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setSearchData(inputValue);
    handleFilterSearchData(inputValue);
  };

  return (
      <>
        <div className="search-field w-100 w-sm-100 w-xs-100 position-relative">
          <div className="static-img">
            <img
              src={searchLogo}
              alt="icon"
              className="searchicon text-black"
            />
          </div>
          <Form.Control
            type="text"
            className="ms-1 cohort-search login-registration-form custom-input-field1"
            placeholder="Search Cohort"
            value={searchData}
            onChange={handleChange}
          />
        </div>
      </>
  );
}

export default CohortSearchComponent;
