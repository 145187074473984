import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Form } from "react-bootstrap";
import exportimages from "../../../assets/images/exportImages";
import searchLogo from "../../../assets/images/Search.png";
import { setDisplayAdditionalInsitituteData } from "../../../Redux/cohortSlice";
import "./CohortAdditionalInstitutionListDisplay.scss";
import { VirtualScroller } from "primereact/virtualscroller";

const CohortAdditionalInstitutionListDisplay = React.forwardRef((props, ref) => {
  const {
    resetFlag,
    setSelectedSortInstitution,
    selectedSortInstitution,
    inputValueInstitution,
    setInputValueInstitution,
    showNotFound,
    resetScrollPositionSelectedInstitution,
    handleDelete,
    visible,
    containerHeight
  } = props;

  const dispatch = useDispatch();

  const sortInstitution = [
    { name: "Institutions: Ascending", code: "institution", order: "ascending" },
    { name: "Institutions: Descending", code: "institution", order: "descending" },
    { name: "Asset size: High to Low", code: "assetsize", order: "descending" },
    { name: "Asset size: Low to High", code: "assetsize", order: "ascending" },
    { name: "State: Ascending", code: "state", order: "ascending" },
    { name: "State: Descending", code: "state", order: "descending" },
  ];

  const { displayAdditionalInsitituteData, originalData, removeDisplayAdditionalInstituteData } = useSelector((state) => state.cohortSlice);

  const handleAllData = (inputValue, searchQuery) => {
    const sortedData = [...originalData];

    if (inputValue?.code === "institution") {
      sortedData.sort((a, b) => {
        return inputValue?.order === "ascending"
          ? a.institutionName.localeCompare(b.institutionName)
          : b.institutionName.localeCompare(a.institutionName);
      });
    } else if (inputValue?.code === "assetsize") {
      sortedData.sort((a, b) => {
        return inputValue?.order === "ascending"
          ? a.assets - b.assets
          : b.assets - a.assets;
      });
    } else if (inputValue?.code === "state") {
      const collator = new Intl.Collator('en', { sensitivity: 'case' });
      sortedData.sort((a, b) => {
        return inputValue?.order === "ascending"
          ? collator.compare(a.state, b.state)
          : collator.compare(b.state, a.state);
      });
    }

    const filteredData = sortedData.filter(item =>
      item?.institutionName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

    dispatch(setDisplayAdditionalInsitituteData(filteredData));
  };

  const handleDropdownData = (dropdownValue) => {
    setSelectedSortInstitution(dropdownValue.value);
    resetScrollPositionSelectedInstitution();
    handleAllData(dropdownValue?.value, inputValueInstitution);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValueInstitution(newValue);
    handleAllData(selectedSortInstitution, newValue);
    resetScrollPositionSelectedInstitution();
  };

  const itemTemplate = (item) => (
    <div className="item-wrapper dropdown-item mt-3" key={item?.idrssd}>
      <p className="mb-0">{item?.institutionName}</p>
      <button
        className={`btn btn-link delete-icon pe-5 me-2 ${resetFlag ? "disabled" : ""}`}
        onClick={(e) => {
          if (resetFlag) return;
          e.preventDefault();
          handleDelete(item);
        }}
      >
        <img
          htmlFor={item?.idrssd}
          className="me-2"
          src={exportimages?.cohortremoveicon}
          alt="Delete"
        />
      </button>
    </div>
  );
  const [resizeCount, setResizeCount] = useState(0);
  const handleResize = () => {
    setResizeCount((prevCount) => prevCount + 1);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-7">
          <div className="p-inputgroup flex-1 mb-3">
            <div className="search-field w-100 w-sm-100 w-xs-100 position-relative">
              <div className="static-img">
                <img
                  src={searchLogo}
                  alt="icon"
                  className="searchicon text-black"
                />
              </div>
              <Form.Control
                type="text"
                className="login-registration-form form-control custom-search-field"
                placeholder={"Search Institutions"}
                value={inputValueInstitution}
                onChange={handleChange}
                disabled={!showNotFound}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="custom-dropdown">
            <Dropdown
              className="filterInstitution"
              value={selectedSortInstitution}
              onChange={handleDropdownData}
              options={sortInstitution}
              optionLabel="name"
              placeholder="Sort"
              disabled={!showNotFound}
            />
            <img
              src={exportimages?.cohortsorticon}
              alt="sort"
              className="custom-dropdown-icon"
            />
          </div>
        </div>
      </div>

      <div className="display-institution position-relative pt-0 ps-2">
        <div className="items-container">
          {displayAdditionalInsitituteData.length === 0 && showNotFound && (
            <div className="no-data-message h-100">
              No data found
            </div>
          )}
          <VirtualScroller
            ref={ref}
            items={displayAdditionalInsitituteData}
            itemSize={38}
            itemTemplate={itemTemplate}
            key={resizeCount} 
            style={{
              height: removeDisplayAdditionalInstituteData?.length === 0 ? (visible ? "330px" : `${containerHeight}px`) : "250px", overflowY: 'auto'
            }}
          />

        </div>
      </div>
    </div>
  );
});

export default CohortAdditionalInstitutionListDisplay;
