import errormessages from "../assets/i18n/en.json";

export const globalError = (error) => {
  error = error.data;
  let ERROR = errormessages.ERROR.CUSTOM.CODE;
  let objError = {
    name: null,
    message: null,
  };
  switch (error.status) {
    case 0: {
      objError.name = "ERROR.CODE.0";
      objError.message = "No Internet or Connectivity";
      // objError.action = null;
      break;
    }
    case 400: {
      //Bad request
      objError.name = "Bad Request"; //ERROR.CODE.400';
      objError.message = error.error;
      // objError.toLogin = false;
      break;
    }
    case 409: {
      objError.name = "ERROR.CUSTOM.CODE." + error?.error?.error; //'ERROR.CODE.400';
      objError.message = "Bad request";
      // objError.toLogin = false;
      break;
    }
    case 401: {
      objError.name = "ERROR.CODE.401";
      objError.message = "User session expired.";
      // objError.toLogin = true;
      break;
    }
    case 403: {
      objError.name = "ERROR.CODE.403";
      objError.message = "Forbidden. Unauthorized User.";
      break;
    }
    case 404: {
      objError.name = "ERROR.CODE.404";
      objError.message = "Resource not found";
      // objError.action = error.status.toString();
      break;
    }
    case 422: {
      objError.name = "ERROR.CODE.422";
      objError.message = "The input data format is incorrect.";
      break;
    }
    case 500: {
      objError.name = "ERROR.CODE.500";
      objError.message = "Some error has occured. Please try again.";
      // objError.action = error.status.toString();
      break;
    }
    case "ERROR": {
      objError.name = error.error;
      objError.message = ERROR[error.error] ?? error.error;
      break;
    }
    case "FAILED": {
      objError.name = error.error;
      objError.message = ERROR[error.error] ?? error.error;
      break;
    }
    default: {
      // error = error
      break;
    }
  }
  if (objError.message === null) {
    return error;
  } else {
    return objError;
  }
};
